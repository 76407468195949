/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OfficerLocationCreate } from '../models/OfficerLocationCreate';
import type { Runsheet } from '../models/Runsheet';
import type { RunsheetCreate } from '../models/RunsheetCreate';
import type { RunsheetTaskCreate } from '../models/RunsheetTaskCreate';
import type { RunsheetUpdate } from '../models/RunsheetUpdate';
import type { RunsheetV2 } from '../models/RunsheetV2';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RunsheetService {

    /**
     * Read Runsheet For Shift Instance
     * @param shiftInstanceId
     * @returns Runsheet Successful Response
     * @throws ApiError
     */
    public static readRunsheetForShiftInstanceApiV1ShiftInstanceShiftInstanceIdRunsheetGet(
        shiftInstanceId: string,
    ): CancelablePromise<Runsheet> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/shift-instance/{shift_instance_id}/runsheet',
            path: {
                'shift_instance_id': shiftInstanceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Runsheet For Shift Instance
     * @param shiftInstanceId
     * @param requestBody
     * @returns Runsheet Successful Response
     * @throws ApiError
     */
    public static createRunsheetForShiftInstanceApiV1ShiftInstanceShiftInstanceIdRunsheetPost(
        shiftInstanceId: string,
        requestBody: RunsheetCreate,
    ): CancelablePromise<Runsheet> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/shift-instance/{shift_instance_id}/runsheet',
            path: {
                'shift_instance_id': shiftInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Runsheet For Shift Instance
     * @param shiftInstanceId
     * @param requestBody
     * @returns Runsheet Successful Response
     * @throws ApiError
     */
    public static updateRunsheetForShiftInstanceApiV1ShiftInstanceShiftInstanceIdRunsheetPut(
        shiftInstanceId: string,
        requestBody: RunsheetUpdate,
    ): CancelablePromise<Runsheet> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/shift-instance/{shift_instance_id}/runsheet',
            path: {
                'shift_instance_id': shiftInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Employee Read Runsheet For Shift Instance
     * @param shiftInstanceId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static employeeReadRunsheetForShiftInstanceApiV1EmployeeShiftInstanceShiftInstanceIdRunsheetGet(
        shiftInstanceId: string,
    ): CancelablePromise<(Runsheet | null)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/employee/shift-instance/{shift_instance_id}/runsheet',
            path: {
                'shift_instance_id': shiftInstanceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Employee Add Task To Runsheet
     * @param id
     * @param requestBody
     * @param isPrioritizedAsNextTask
     * @returns Runsheet Successful Response
     * @throws ApiError
     */
    public static employeeAddTaskToRunsheetApiV1EmployeeRunsheetIdAddTaskPut(
        id: string,
        requestBody: RunsheetTaskCreate,
        isPrioritizedAsNextTask: boolean = false,
    ): CancelablePromise<Runsheet> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/employee/runsheet/{id}/add-task',
            path: {
                'id': id,
            },
            query: {
                'is_prioritized_as_next_task': isPrioritizedAsNextTask,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Employee Check In To Site For Runsheet
     * @param id
     * @param taskId
     * @param requestBody
     * @returns Runsheet Successful Response
     * @throws ApiError
     */
    public static employeeCheckInToSiteForRunsheetApiV1EmployeeRunsheetIdCheckInTaskIdPut(
        id: string,
        taskId: string,
        requestBody?: (OfficerLocationCreate | null),
    ): CancelablePromise<Runsheet> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/employee/runsheet/{id}/check-in/{task_id}',
            path: {
                'id': id,
                'task_id': taskId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Employee Check Out Of Site For Runsheet
     * @param id
     * @param taskId
     * @param officerSummary
     * @param requestBody
     * @returns Runsheet Successful Response
     * @throws ApiError
     */
    public static employeeCheckOutOfSiteForRunsheetApiV1EmployeeRunsheetIdCheckOutTaskIdPut(
        id: string,
        taskId: string,
        officerSummary?: (string | null),
        requestBody?: (OfficerLocationCreate | null),
    ): CancelablePromise<Runsheet> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/employee/runsheet/{id}/check-out/{task_id}',
            path: {
                'id': id,
                'task_id': taskId,
            },
            query: {
                'officer_summary': officerSummary,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Employee Complete Task For Runsheet
     * @param id
     * @param taskId
     * @param officerSummary
     * @returns Runsheet Successful Response
     * @throws ApiError
     */
    public static employeeCompleteTaskForRunsheetApiV1EmployeeRunsheetIdCompleteTaskIdPut(
        id: string,
        taskId: string,
        officerSummary?: (string | null),
    ): CancelablePromise<Runsheet> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/employee/runsheet/{id}/complete/{task_id}',
            path: {
                'id': id,
                'task_id': taskId,
            },
            query: {
                'officer_summary': officerSummary,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Runsheet For Shift Instance
     * @param shiftInstanceId
     * @param requestBody
     * @returns RunsheetV2 Successful Response
     * @throws ApiError
     */
    public static createRunsheetForShiftInstanceApiV2ShiftInstanceShiftInstanceIdRunsheetPost(
        shiftInstanceId: string,
        requestBody: RunsheetCreate,
    ): CancelablePromise<RunsheetV2> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/shift-instance/{shift_instance_id}/runsheet',
            path: {
                'shift_instance_id': shiftInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Runsheet For Shift Instance
     * @param shiftInstanceId
     * @returns RunsheetV2 Successful Response
     * @throws ApiError
     */
    public static getRunsheetForShiftInstanceApiV2ShiftInstanceShiftInstanceIdRunsheetGet(
        shiftInstanceId: string,
    ): CancelablePromise<RunsheetV2> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/shift-instance/{shift_instance_id}/runsheet',
            path: {
                'shift_instance_id': shiftInstanceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Admin Runsheet For Shift Instance
     * When viewed by the admin, we want to return a nascent runsheet if the runsheet has not been created yet.
     * This is to allow the admin to view the runsheet for the shift instance without disconnecting it from the template.
     *
     * If the admin wants to modify the runsheet, they'll need to first hit the create endpoint. Which will disconnect the runsheet from the template.
     * Alternatively, the runsheet will be created at clock in by the officer.
     * @param shiftInstanceId
     * @returns RunsheetV2 Successful Response
     * @throws ApiError
     */
    public static getAdminRunsheetForShiftInstanceApiV2ShiftInstanceShiftInstanceIdRunsheetAdminGet(
        shiftInstanceId: string,
    ): CancelablePromise<RunsheetV2> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v2/shift-instance/{shift_instance_id}/runsheet/admin',
            path: {
                'shift_instance_id': shiftInstanceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Runsheet For Shift Instance From Template
     * @param shiftInstanceId
     * @returns RunsheetV2 Successful Response
     * @throws ApiError
     */
    public static createRunsheetForShiftInstanceFromTemplateApiV2ShiftInstanceShiftInstanceIdRunsheetTemplatePut(
        shiftInstanceId: string,
    ): CancelablePromise<RunsheetV2> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v2/shift-instance/{shift_instance_id}/runsheet/template',
            path: {
                'shift_instance_id': shiftInstanceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
