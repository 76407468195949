/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Ordering } from './Ordering';

export type AuditRecordFilter = {
    user_id?: string;
    user_name?: string;
    action?: AuditRecordFilter.action;
    entity_id?: string;
    entity?: string;
    entity_label?: string;
    field?: string;
    field_label?: string;
    date_from?: string;
    date_to?: string;
    order?: Array<Ordering>;
    general_match?: boolean;
    user_ids?: Array<string>;
    entity_name?: string;
};

export namespace AuditRecordFilter {

    export enum action {
        CREATE = 'Create',
        UPDATE = 'Update',
        DELETE = 'Delete',
        ADD = 'Add',
        REMOVE = 'Remove',
    }


}

