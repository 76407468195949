/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BelfryAPICustomerServiceRateCreateUpdate } from '../models/BelfryAPICustomerServiceRateCreateUpdate';
import type { CustomerServiceRate } from '../models/CustomerServiceRate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomerServiceRateService {

    /**
     * Read Customer Service Rates
     * Returns services for customer with given ID
     * @param customerId
     * @returns CustomerServiceRate Successful Response
     * @throws ApiError
     */
    public static readCustomerServiceRatesApiV1CustomerCustomerIdServiceRateGet(
        customerId: string,
    ): CancelablePromise<Array<CustomerServiceRate>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/customer/{customer_id}/service_rate',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Update Customer Service Rate
     * Create and update services for customer
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static createUpdateCustomerServiceRateApiV1CustomerServiceRatesPost(
        requestBody: BelfryAPICustomerServiceRateCreateUpdate,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/customer/service_rates',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
