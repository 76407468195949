/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AccountsPayableInvoiceStatus {
    DRAFT = 'Draft',
    DELETED = 'Deleted',
    OPEN = 'Open',
    VOID = 'Void',
    UNCOLLECTIBLE = 'Uncollectible',
    UNCOLLECTIBLE_PAID = 'Uncollectible Paid',
    SHORT_PAID = 'Short Paid',
    PAID = 'Paid',
}
