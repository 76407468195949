import { toast } from 'react-toastify'
import useSWR from 'swr'
import {
    TimecardService,
    ScheduleShiftInstance,
    ShiftInstanceTimekeepingUpdate,
    ShiftDetails,
    Schedule,
    FlatratePayrollResponse,
    OfficerForTimekeepingView,
    TimekeepingShiftInstance,
} from '../generated'
import { PayrollServicesEnum } from '../generated/models/PayrollServicesEnum'
import { detailedLinkErrorToToast } from '../components/Payroll/detailedLinkErrorToToast'

import { useUserIsPayrollPrivileged } from '../utils/auth'
import { doesFirmHavePayrollAddonHelper, useLoadFirmWithAddons } from './firm'
import { handleFileResponse } from 'src/utils/downloads'
import {
    updateShiftDetails as updateShiftDetailsNew,
    updateShiftInstancesForScheduleTK as updateShiftInstancesForScheduleTKNew,
    updateShiftInstancesForTK as updateShiftInstancesForTKNew,
} from 'src/services/timecard_v2'
import { ScopedMutator } from 'swr/dist/_internal'
// even though it's only one day, we need to pass startDate and endDate for timekeeping engine
type LoadShiftInstanceFunc = (
    targetDate: string,
    startDate: string,
    endDate: string,
    v4ScheduleFlag: boolean
) => {
    shift_instances: ScheduleShiftInstance[] | void
    isLoading: boolean
    isError: boolean
}
export const useLoadShiftInstancesForTKDay: LoadShiftInstanceFunc = (
    targetDate,
    startDate,
    endDate,
    v4ScheduleFlag
) => {
    const key = !v4ScheduleFlag
        ? [`/timecards/`, targetDate, targetDate, startDate, endDate, undefined]
        : null
    const { data, error } = useSWR(key, () =>
        TimecardService.getShiftInstancesForDateApiV1TimecardsShiftsDayGet(
            targetDate,
            startDate,
            endDate
        )
    )
    return { shift_instances: data, isLoading: !error && !data, isError: error }
}

type LoadShiftInstancePeriodFunc = (
    startDate: string,
    endDate: string,
    payGroupId: string | undefined,
    v4ScheduleFlag: boolean
) => {
    shift_instances: ScheduleShiftInstance[] | void
    isLoading: boolean
    isError: boolean
}
export const useLoadShiftInstancesForTKPeriod: LoadShiftInstancePeriodFunc = (
    startDate,
    endDate,
    payGroupId,
    v4ScheduleFlag
) => {
    const { data, error } = useSWR(
        !v4ScheduleFlag
            ? [
                  `/timecards/`,
                  startDate,
                  endDate,
                  startDate,
                  endDate,
                  payGroupId,
              ]
            : null,
        () =>
            TimecardService.getShiftInstancesForDateRangeApiV1TimecardsShiftsPeriodGet(
                startDate,
                endDate,
                payGroupId
            )
    )
    return { shift_instances: data, isLoading: !error && !data, isError: error }
}

type LockAllShiftInstancePeriodFunc = (
    startDate: string,
    endDate: string,
    shitInstanceIds: string[],
    mutate: any,
    payGroupId: string | undefined
) => void
export const lockAllShiftInstancesForTKPeriod: LockAllShiftInstancePeriodFunc =
    (startDate, endDate, shiftInstanceIds, mutate, payGroupId) => {
        return mutate(
            [`/timecards/`, startDate, endDate, startDate, endDate, payGroupId],
            () =>
                TimecardService.lockShiftInstancesForDateApiV1TimecardsShiftsLockPut(
                    startDate,
                    endDate,
                    shiftInstanceIds
                )
        )
    }

export const unlockAllShiftInstancesForTKPeriod: LockAllShiftInstancePeriodFunc =
    (startDate, endDate, shiftInstanceIds, mutate, payGroupId) => {
        return mutate(
            [`/timecards/`, startDate, endDate, startDate, endDate, payGroupId],
            () =>
                TimecardService.unlockShiftInstancesForDateApiV1TimecardsShiftsUnlockPut(
                    startDate,
                    endDate,
                    shiftInstanceIds
                )
        )
    }
// XXX if there's no target date (pay periods) pass false as the first arg
type UpdateTimecardsFunc = (
    targetDate: string | false,
    startDate: string,
    endDate: string,
    shift_instances: ShiftInstanceTimekeepingUpdate[],
    mutate: ScopedMutator,
    v4ScheduleFlag: boolean,
    payGroupId?: string | undefined
) => Promise<ScheduleShiftInstance[] | TimekeepingShiftInstance[] | undefined>
export const updateShiftInstancesForTK: UpdateTimecardsFunc = (
    targetDate,
    startDate,
    endDate,
    shift_instances,
    mutate,
    v4ScheduleFlag,
    payGroupId = undefined
) => {
    if (v4ScheduleFlag) {
        return updateShiftInstancesForTKNew(
            targetDate,
            startDate,
            endDate,
            shift_instances,
            mutate,
            payGroupId
        )
    }
    const firstTwo = targetDate
        ? [targetDate, targetDate]
        : [startDate, endDate]
    const key = [`/timecards/`, ...firstTwo, startDate, endDate, payGroupId]
    return mutate(
        key,
        async (existingInstances: ScheduleShiftInstance[] | undefined) => {
            const updatedShifts =
                await TimecardService.updateShiftInstancesApiV2TimecardsShiftsUpdatePut(
                    startDate,
                    endDate,
                    shift_instances,
                    targetDate ? targetDate : undefined
                )
            const updatedScheduleIds = updatedShifts.map(
                (shiftInstance) => shiftInstance.id
            )
            const filteredSchedule =
                existingInstances?.filter(
                    (shiftInstance: ScheduleShiftInstance) =>
                        !updatedScheduleIds.includes(shiftInstance.id)
                ) ?? []
            // invalidate the cache for the details for all edited instances
            shift_instances.map((inst: ShiftInstanceTimekeepingUpdate) => {
                return mutate(
                    ['schedule-short-details', inst.id],
                    undefined,
                    true
                )
            })
            return [...updatedShifts, ...filteredSchedule]
        }
    )
}

// Extremely similar to the above, but for the schedule, target date is just to keep signature the same
type updateShiftInstancesForScheduleTKFunc = (
    targetDate: string | false,
    startDate: string,
    endDate: string,
    shift_instances: ShiftInstanceTimekeepingUpdate[],
    mutate: ScopedMutator,
    v4ScheduleFlag: boolean
) => Promise<Schedule | TimekeepingShiftInstance[] | undefined>
export const updateShiftInstancesForScheduleTK: updateShiftInstancesForScheduleTKFunc =
    (
        targetDate,
        startDate,
        endDate,
        shift_instances,
        mutate,
        v4ScheduleFlag
    ) => {
        if (v4ScheduleFlag) {
            return updateShiftInstancesForScheduleTKNew(
                targetDate,
                startDate,
                endDate,
                shift_instances,
                mutate
            )
        }
        const key = `/schedule?start_date=${startDate}&end_date=${endDate}`
        return mutate(key, async (schedule: Schedule | undefined) => {
            const updatedShifts =
                await TimecardService.updateShiftInstancesApiV2TimecardsShiftsUpdatePut(
                    startDate,
                    endDate,
                    shift_instances,
                    targetDate ? targetDate : undefined
                )
            const existingInstances = schedule?.shift_schedule ?? []
            const updatedScheduleIds = updatedShifts.map(
                (shiftInstance) => shiftInstance.id
            )
            const filteredSchedule = existingInstances.filter(
                (shiftInstance: ScheduleShiftInstance) =>
                    !updatedScheduleIds.includes(shiftInstance.id)
            )
            // invalidate the cache for the details for all edited instances
            shift_instances.map((inst: ShiftInstanceTimekeepingUpdate) => {
                return mutate(
                    ['schedule-short-details', inst.id],
                    undefined,
                    true
                )
            })
            return {
                shift_schedule: [...updatedShifts, ...filteredSchedule],
                called_off_shifts: [],
            }
        })
    }

type downloadTimecardCSVExportFunc = (
    kind: PayrollServicesEnum,
    firm_payroll_external_id: string,
    startDate: string,
    endDate: string,
    includeUnapproved: boolean,
    exportInDollars: boolean,
    payGroupId?: string | undefined,
    columnsToExclude?: string[]
) => Promise<any>
export const downloadTimecardCSVExport: downloadTimecardCSVExportFunc = async (
    kind,
    firm_payroll_external_id,
    startDate,
    endDate,
    includeUnapproved,
    exportInDollars,
    payGroupId,
    columnsToExclude
) => {
    const fileName = `PR_${startDate}_to_${endDate}.csv`
    try {
        return TimecardService.getTimecardsExportApiV2TimecardsExportGet(
            kind,
            startDate,
            endDate,
            includeUnapproved,
            exportInDollars,
            payGroupId,
            columnsToExclude
        ).then(handleFileResponse({ fileName, mimeType: 'text/csv' }))
    } catch (error) {
        //@ts-ignore
        toast.error(error.body.detail)
    }
}

type DownloadScheduleFunc = (
    officerName: string,
    officerId: string,
    startDate: string,
    endDate: string
) => any
export const downloadOfficerTimecard: DownloadScheduleFunc = async (
    officerName,
    officerId,
    startDate,
    endDate
) => {
    try {
        return TimecardService.exportForOfficerTimekeepingApiV1TimecardsOfficerExportOfficerIdGet(
            officerId,
            startDate,
            endDate
        ).then(
            handleFileResponse({
                fileName: `${officerName}_timekeeping_export_${startDate}_${endDate}.csv`,
                mimeType: 'text/csv',
            })
        )
    } catch (error) {
        //@ts-ignore
        toast.error(error.body.detail)
    }
}

type LoadNonHourlyPayItemsFunc = (
    startDate: string,
    endDate: string,
    payGroupId: string | undefined
) => FlatratePayrollResponse | undefined
export const useLoadNonHourlyPayItemsAndOfficersForPeriod: LoadNonHourlyPayItemsFunc =
    (startDate, endDate, payGroupId) => {
        const isPayrollPrivileged = useUserIsPayrollPrivileged()
        const { firm } = useLoadFirmWithAddons()
        const firmHasPayrollAddon = firm
            ? doesFirmHavePayrollAddonHelper(firm)
            : false
        const shouldFetch =
            isPayrollPrivileged &&
            firmHasPayrollAddon &&
            firm &&
            firm.checkhq_id &&
            firm.checkhq_default_sig_id
        const { data, error } = useSWR(
            shouldFetch
                ? [
                      `/timecards_non_hourly/payroll_period/`,
                      startDate,
                      endDate,
                      payGroupId,
                  ]
                : null,
            () => {
                return TimecardService.getNonHourlyPayForDateRangeToPayrollApiV2TimecardsNonHourlyPayrollPeriodGet(
                    startDate,
                    endDate,
                    payGroupId
                ).catch((error) => {
                    detailedLinkErrorToToast(error, 10000)
                })
            }
        )
        if (shouldFetch) {
            return data ? data : undefined
        } else {
            return {
                non_hourly_pay_items: [],
                officers: [],
            }
        }
    }

type LoadAssignedOfficersForPeriodFunc = (
    startDate: string,
    endDate: string
) => {
    officers: OfficerForTimekeepingView[] | void
    isLoading: boolean
    isError: boolean
}
export const useLoadAssignedOfficersForPeriod: LoadAssignedOfficersForPeriodFunc =
    (startDate, endDate) => {
        const key = [`timecards_officers_for_period`, startDate, endDate]
        const { data, error } = useSWR(key, () =>
            TimecardService.getOfficersForDateRangeApiV1TimecardsPayrollPeriodOfficersGet(
                startDate,
                endDate
            )
        )
        return { officers: data, isLoading: !error && !data, isError: error }
    }
type UpdateShiftDetailsFunc = (
    targetDate: string | false,
    startDate: string,
    endDate: string,
    shiftInstanceId: string,
    shiftDetailId: string,
    shiftDetails: ShiftDetails,
    mutate: ScopedMutator,
    v4ScheduleFlag: boolean,
    payGroupId: string | undefined
) => Promise<ScheduleShiftInstance[] | TimekeepingShiftInstance[] | undefined>
export const updateShiftDetails: UpdateShiftDetailsFunc = (
    targetDate,
    startDate,
    endDate,
    shiftInstanceId,
    shiftDetailId,
    shiftDetails,
    mutate,
    v4ScheduleFlag,
    payGroupId
) => {
    if (v4ScheduleFlag) {
        return updateShiftDetailsNew(
            targetDate,
            startDate,
            endDate,
            shiftInstanceId,
            shiftDetailId,
            shiftDetails,
            mutate,
            payGroupId
        )
    }
    const firstTwo = targetDate
        ? [targetDate, targetDate]
        : [startDate, endDate]
    const key = [`/timecards/`, ...firstTwo, startDate, endDate, payGroupId]
    return mutate(
        key,
        async (existingInstances: ScheduleShiftInstance[] | undefined) => {
            const updatedShifts =
                await TimecardService.updateShiftDetailsApiV2TimekeepingShiftInstanceIdDetailsShiftDetailIdPut(
                    shiftInstanceId,
                    shiftDetailId,
                    startDate,
                    endDate,
                    shiftDetails,
                    targetDate ? targetDate : undefined
                )
            const updatedScheduleIds = updatedShifts.map(
                (shiftInstance) => shiftInstance.id
            )
            const filteredSchedule =
                existingInstances?.filter(
                    (shiftInstance: ScheduleShiftInstance) =>
                        !updatedScheduleIds.includes(shiftInstance.id)
                ) ?? []
            mutate(['schedule-short-details', shiftInstanceId], undefined, true)
            return [...updatedShifts, ...filteredSchedule]
        }
    )
}
