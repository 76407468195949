import { Box, Tooltip, styled } from '@mui/material'
import { add, parseISO } from 'date-fns'
import {
    isClockInWithinThreshHelper,
    isSchedMismatchHelper,
} from 'src/components/Timekeeping/helpers'
import { withRole } from 'src/components/withRole'
import {
    OfficerMetadata,
    ScheduleShiftInstance,
    Settings,
    ShiftInstanceStatus,
    WageCategory,
    WageCategoryReasons,
    WageResult,
} from 'src/generated'
import { MobiscrollShiftInstance } from 'src/types/mobiscrollShiftInstance'
import { objectEntries } from 'src/utils/arr'
import {
    DayBoxInnerDisplayKeysType,
    EitherMobiscrollOrScheduleShiftInstance,
    hasGroupMetadata,
    ShiftInstanceWithScheduledDetails,
} from './types'
import React from 'react'
import { useRouter } from 'next/router'
import { SECONDS_IN_HOUR } from 'src/constants/dateConstants'
import { NewShiftInstance } from 'src/types/newShiftInstance'

export const DAY_BOX_MAIN_TEXT_FONT_SIZE = 14
// export const DAY_BOX_HEIGHT = 'auto'
export const SCHEDULE_FILTER_PANEL_WIDTH = '250px'

export const DayBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '8px',
    paddingTop: '4px',
    height: '100%',
    transition: 'all ease 0.2s',
    border: '1px solid rgba(0, 0, 0, 0)',
    '&:hover': {
        backgroundColor: '#e9dcdc',
    },
})

export const primaryGrayTextColor = '#383a3a'
export const secondaryGrayTextColor = '#757777'

// TODO: move this to theme
export const ShiftColorMap: {
    [key: string]: { backgroundColor: string; color: string }
} = {
    open: {
        backgroundColor: '#FFECEC',
        color: '#E92C2C',
    },
    unpublished: {
        backgroundColor: '#F5F8FF',
        color: '#4338CA',
    },
    published: {
        backgroundColor: '#FFF',
        color: '#000',
    },
    actively_occurring_started_on_time: {
        backgroundColor: '#d8fbd2',
        color: '#000',
    },
    actively_occurring_mismatched_time: {
        backgroundColor: '#ffd1a8',
        color: '#000',
    },
    past_on_time: {
        backgroundColor: '#d8fbd2',
        color: '#000',
    },
    past_missing_punches: {
        backgroundColor: '#FFECEC',
        color: '#000',
    },
    past_mismatched_time: {
        backgroundColor: '#ffd1a8',
        color: '#000',
    },
}

export const TooltipForAdmin = withRole(['Admin'])(Tooltip)

export function getDisplayKeyForPublishedInstance(
    instance: MobiscrollShiftInstance,
    settings: Settings
): DayBoxInnerDisplayKeysType {
    const scheduled_start = parseISO(instance.shift_start_date_time as string)
    const scheduled_end = parseISO(instance.shift_end_date_time as string)
    const TODAY = new Date()
    const TODAY_FIFTEEN_AHEAD = add(TODAY, { minutes: 15 })
    if (scheduled_start > TODAY_FIFTEEN_AHEAD) {
        // it hasn't occurred yet, just a normal published shift > 15 minutes in the future
        return instance.status
    } else if (scheduled_end > TODAY) {
        // we are within 15 mins of the start time
        if (isClockInWithinThreshHelper(instance, settings)) {
            // shift has started and user clocked in on time
            return 'actively_occurring_started_on_time'
        } else if (instance.clock_in) {
            // shift has started and user clocked in with mismatched time
            return 'actively_occurring_mismatched_time'
        } else if (
            scheduled_start > TODAY &&
            scheduled_start <= TODAY_FIFTEEN_AHEAD
        ) {
            // it is about to start within 15 minutes, user hasn't clocked in
            return 'actively_occurring_about_to_start'
        } else {
            // it has started, user hasn't clocked in
            return 'actively_occurring_missing_punches'
        }
    } else {
        // it's occurred in the past
        if (instance.clock_in && instance.clock_out) {
            if (isSchedMismatchHelper(instance, settings)) {
                return 'past_mismatched_time'
            } else {
                return 'past_on_time'
            }
        } else {
            return 'past_missing_punches'
        }
    }
}

// logic for determining instance colors and relevant icons
export function getDisplayKeyForInstance(
    instance: MobiscrollShiftInstance,
    settings: Settings
) {
    if (instance.grouped && instance.group_metadata) {
        return 'Grouped'
    }
    if (instance.status !== ShiftInstanceStatus.PUBLISHED) {
        return instance.status
    }
    return getDisplayKeyForPublishedInstance(instance, settings)
}

export enum BottomState {
    DETAILS = 'Details',
    DELETING = 'Deleting',
    EDITING = 'Editing',
    EDITING_BILLING = 'Editing Billing',
    CALLOFF = 'CallOff',
    SPLITING = 'Spliting',
    NOTES = 'Notes',
    ADD_SLOTS = 'Add Slots',
}

export type BoolFilterOptionKeyType =
    | 'open_shift'
    | 'overtime'
    | 'conflict'
    | 'unconfirmed'
    | 'missing_punches'
    | 'training_shifts'

export type BoolFilterOptionsType = {
    [filter_option_key in BoolFilterOptionKeyType]: boolean
}

export const BOOL_FILTER_OPTIONS: {
    key: BoolFilterOptionKeyType
    label: string
    tip: string
}[] = [
    { key: 'open_shift', label: 'Open', tip: 'Shifts not assigned to anyone' },
    {
        key: 'overtime',
        label: 'Overtime',
        tip: 'Shifts that contribute to OT hours',
    },
    {
        key: 'conflict',
        label: 'Conflicting',
        tip: 'Shifts with double-booked officers',
    },
    {
        key: 'unconfirmed',
        label: 'Unconfirmed',
        tip: 'Shifts with no officer confirmation',
    },
    {
        key: 'missing_punches',
        label: 'Missing Punches',
        tip: 'Past & currently occurring shifts with no clock-in or no clock-out',
    },
    {
        key: 'training_shifts',
        label: 'Training Shifts',
        tip: 'Shift flagged as for training purposes',
    },
]

// XXX these keys *must* match BOOL_FILTER_OPTIONS keys
export const BOOL_FILTER_PREDICATE_LOOKUP_V1: {
    [filter_option_key in BoolFilterOptionKeyType]: (
        shift_instance: ScheduleShiftInstance
    ) => boolean
} = {
    open_shift: (shift_instance: ScheduleShiftInstance) =>
        shift_instance.officer == undefined,
    overtime: (shift_instance: ScheduleShiftInstance) =>
        !!(
            shift_instance.overtime_warning ||
            shift_instance?.scheduled_shift_details?.daily_overtime_hours
        ),
    conflict: (shift_instance: ScheduleShiftInstance) =>
        !!shift_instance.conflicting_assignment,
    unconfirmed: (shift_instance: ScheduleShiftInstance) =>
        !shift_instance.confirmed_at && shift_instance.officer !== undefined,
    missing_punches: (shift_instance: ScheduleShiftInstance) => {
        if (shift_instance.clock_in && shift_instance.clock_out) {
            return false
        }
        const scheduled_start = parseISO(
            shift_instance.shift_start_date_time as string
        )
        const scheduled_end = parseISO(
            shift_instance.shift_end_date_time as string
        )
        const TODAY = new Date()
        if (scheduled_start > TODAY) {
            // it starts in the future
            return false
        } else if (scheduled_end > TODAY) {
            // it's currently occurring, so should have a clock in
            return !shift_instance.clock_in
        } else {
            // it ended in the past so should have both punches
            return !(shift_instance.clock_in && shift_instance.clock_out)
        }
    },
    training_shifts: (shift_instance: ScheduleShiftInstance) => {
        return shift_instance.is_training_shift
    },
}
// XXX this is identical to BOOL_FILTER_PREDICATE_LOOKUP_V1 other than the "overtime" filter and types
// XXX these keys *must* match BOOL_FILTER_OPTIONS keys
export const BOOL_FILTER_PREDICATE_LOOKUP_V2: {
    [filter_option_key in BoolFilterOptionKeyType]: (
        shift_instance: NewShiftInstance
    ) => boolean
} = {
    open_shift: (shift_instance: NewShiftInstance) =>
        shift_instance.officer == undefined,
    overtime: (shift_instance: NewShiftInstance) => {
        if (shift_instance.clock_in && shift_instance.clock_out) {
            return !!(
                shift_instance.actual_wage_results?.some(
                    wage_result_is_daily_ot_or_dt
                ) ||
                shift_instance.actual_wage_results?.some(
                    wage_result_is_weekly_ot_or_dt
                )
            )
        }
        return !!(
            shift_instance.scheduled_wage_results?.some(
                wage_result_is_daily_ot_or_dt
            ) ||
            shift_instance.scheduled_wage_results?.some(
                wage_result_is_weekly_ot_or_dt
            )
        )
    },
    conflict: (shift_instance: NewShiftInstance) =>
        !!shift_instance.conflicting_assignment,
    unconfirmed: (shift_instance: NewShiftInstance) =>
        !shift_instance.confirmed_at && shift_instance.officer !== undefined,
    missing_punches: (shift_instance: NewShiftInstance) => {
        if (shift_instance.clock_in && shift_instance.clock_out) {
            return false
        }
        const scheduled_start = parseISO(
            shift_instance.shift_start_date_time as string
        )
        const scheduled_end = parseISO(
            shift_instance.shift_end_date_time as string
        )
        const TODAY = new Date()
        if (scheduled_start > TODAY) {
            // it starts in the future
            return false
        } else if (scheduled_end > TODAY) {
            // it's currently occurring, so should have a clock in
            return !shift_instance.clock_in
        } else {
            // it ended in the past so should have both punches
            return !(shift_instance.clock_in && shift_instance.clock_out)
        }
    },
    training_shifts: (shift_instance: NewShiftInstance) => {
        return shift_instance.is_training_shift
    },
}

export const generateInitialBoolFilterState = () => {
    const reducer = (prev: any, cur: any) => {
        prev[cur.key] = false
        return prev
    }
    return BOOL_FILTER_OPTIONS.reduce(reducer, {})
}

export const getTrueBoolFilterKeys = (
    state: BoolFilterOptionsType
): BoolFilterOptionKeyType[] => {
    const arr = objectEntries(state)
    return arr.filter((arr) => arr[1]).map((arr) => arr[0])
}

export const calculateTotalHoursFromInstancesAsNumberV1 = (
    arr: ShiftInstanceWithScheduledDetails[]
) => {
    return arr.reduce(instance_sched_hours_reducer_v1, 0)
}

export const calculateTotalHoursFromInstancesV1 = (
    arr: ShiftInstanceWithScheduledDetails[]
) => {
    return calculateTotalHoursFromInstancesAsNumberV1(arr)
        .toFixed(1)
        .replace('.0', '')
}
export const calculateTotalHoursFromInstancesAsNumberV2 = (
    arr: Pick<NewShiftInstance, 'scheduled_length'>[]
) => {
    return arr.reduce(instance_sched_hours_reducer_v2, 0)
}

export const calculateTotalHoursFromInstancesV2 = (
    arr: Pick<NewShiftInstance, 'scheduled_length'>[]
) => {
    return calculateTotalHoursFromInstancesAsNumberV2(arr)
        .toFixed(1)
        .replace('.0', '')
}

export const instance_sched_hours_reducer_v1 = (
    acc: number,
    cur: ShiftInstanceWithScheduledDetails
) => {
    const total_hours =
        cur.scheduled_shift_details && cur.scheduled_shift_details.total_hours
            ? cur.scheduled_shift_details.total_hours
            : 0
    return acc + total_hours
}

export const instance_sched_hours_reducer_v2 = (
    acc: number,
    cur: Pick<NewShiftInstance, 'scheduled_length'>
) => {
    const total_hours = cur.scheduled_length
        ? cur.scheduled_length / SECONDS_IN_HOUR
        : 0
    return acc + total_hours
}
export const instance_actual_hours_reducer_v2 = (
    acc: number,
    cur: Pick<NewShiftInstance, 'actual_wage_results'>
) => {
    const total_hours = cur.actual_wage_results
        ? cur.actual_wage_results.reduce(
              (acc: number, cur: WageResult) => acc + cur.length,
              0
          )
        : 0
    return acc + total_hours
}

export const instance_sched_ot_hours_reduce_v1 = (
    acc: number,
    cur: ShiftInstanceWithScheduledDetails
) => {
    const total_hours =
        cur.scheduled_shift_details &&
        cur.scheduled_shift_details.total_overtime_hours
            ? cur.scheduled_shift_details.total_overtime_hours
            : 0
    return acc + total_hours
}

export const instance_sched_ot_hours_reducer_v2 = (
    acc: number,
    cur: Pick<NewShiftInstance, 'scheduled_wage_results'>
) => {
    const total_hours =
        cur.scheduled_wage_results
            ?.filter((a) =>
                [WageCategory.OT, WageCategory.DT].includes(a.category)
            )
            .reduce((acc: number, cur: WageResult) => acc + cur.length, 0) || 0
    return acc + total_hours
}

export const total_scheduled_reducer_v1 = (acc: number, cur: any) => {
    return acc + cur.shift_instances.reduce(instance_sched_hours_reducer_v1, 0)
}
export const total_scheduled_reducer_v2 = (acc: number, cur: any) => {
    return acc + cur.shift_instances.reduce(instance_sched_hours_reducer_v2, 0)
}

export const wage_result_is_daily_ot_or_dt = (wage_result: WageResult) => {
    return (
        [WageCategory.OT, WageCategory.DT].includes(wage_result.category) &&
        wage_result.reason &&
        [WageCategoryReasons.DAILY_OT, WageCategoryReasons.DAILY_DT].includes(
            wage_result.reason
        )
    )
}
export const wage_result_is_weekly_ot_or_dt = (wage_result: WageResult) => {
    return (
        [WageCategory.OT, WageCategory.DT].includes(wage_result.category) &&
        wage_result.reason &&
        [WageCategoryReasons.WEEKLY_OT, WageCategoryReasons.WEEKLY_DT].includes(
            wage_result.reason
        )
    )
}

export const generalBorder = '1px solid rgb(200,200,200)'

export const degroupShiftInstances = (
    shift_instances: EitherMobiscrollOrScheduleShiftInstance[]
) => {
    return shift_instances
        .map((inst) => {
            if (
                hasGroupMetadata(inst) &&
                inst.grouped &&
                inst.group_metadata &&
                inst.group_metadata.shift_instances
            ) {
                return inst.group_metadata.shift_instances
            } else {
                return inst
            }
        })
        .flat(1)
}

export const SchedGridBulkSelContext = React.createContext<{
    isBulkSelMode: boolean
    setIsBulkSelMode: React.Dispatch<React.SetStateAction<boolean>>
    bulkSelectedIds: string[]
    setBulkSelectedIds: React.Dispatch<React.SetStateAction<string[]>>
    // @ts-ignore
}>(null)

/*eslint-disable react/display-name  */
export const withSchedGridBulkSelectionContext =
    (Component: any) => (props: any) => {
        const [isBulkSelMode, setIsBulkSelMode] = React.useState(false)
        const [bulkSelectedIds, setBulkSelectedIds] = React.useState<string[]>(
            []
        )
        const value = {
            isBulkSelMode,
            setIsBulkSelMode,
            bulkSelectedIds,
            setBulkSelectedIds,
        }
        const handleRouteChange = () => {
            setIsBulkSelMode(false)
            setBulkSelectedIds([])
        }
        // use this to reset when we change weeks/dates
        const router = useRouter()
        React.useEffect(() => {
            router.events.on('routeChangeStart', handleRouteChange)
            return () => {
                router.events.off('routeChangeStart', handleRouteChange)
            }
        }, [router.events])

        return (
            <SchedGridBulkSelContext.Provider value={value}>
                <Component {...props} />
            </SchedGridBulkSelContext.Provider>
        )
    }

export function postAndMaybePositionNameForDisplay(shiftInstance: {
    post_name: string
    position_name?: string
}) {
    if (
        !shiftInstance.position_name ||
        shiftInstance.position_name === shiftInstance.post_name
    ) {
        return shiftInstance.post_name
    } else {
        return `${shiftInstance.position_name} - ${shiftInstance.post_name}`
    }
}

export type AssignedShiftInstanceType = {
    officer: OfficerMetadata
} & EitherMobiscrollOrScheduleShiftInstance

export function instance_has_officer_assigned(
    inst: Pick<
        EitherMobiscrollOrScheduleShiftInstance | NewShiftInstance,
        'officer'
    >
): inst is AssignedShiftInstanceType {
    return (
        (inst as AssignedShiftInstanceType).officer &&
        typeof inst.officer !== undefined &&
        typeof inst.officer?.id == 'string'
    )
}
