/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_create_certifications_api_v1_bulk_certification_post } from '../models/Body_create_certifications_api_v1_bulk_certification_post';
import type { Body_create_contacts_api_v1_bulk_contact_post } from '../models/Body_create_contacts_api_v1_bulk_contact_post';
import type { Body_create_customers_api_v1_bulk_customer_post } from '../models/Body_create_customers_api_v1_bulk_customer_post';
import type { Body_create_holidays_api_v1_bulk_holiday_post } from '../models/Body_create_holidays_api_v1_bulk_holiday_post';
import type { Body_create_licenses_api_v1_bulk_license_post } from '../models/Body_create_licenses_api_v1_bulk_license_post';
import type { Body_create_officer_api_v1_bulk_officer_post } from '../models/Body_create_officer_api_v1_bulk_officer_post';
import type { Body_create_shifts_api_v1_bulk_shift_post } from '../models/Body_create_shifts_api_v1_bulk_shift_post';
import type { Body_update_contract_api_v1_bulk_contract_post } from '../models/Body_update_contract_api_v1_bulk_contract_post';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BulkUploadService {

    /**
     * Download Officer Template
     * @returns any Successful Response
     * @throws ApiError
     */
    public static downloadOfficerTemplateApiV1BulkOfficerTemplateGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bulk/officer/template',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Download Officer Regions
     * @returns any Successful Response
     * @throws ApiError
     */
    public static downloadOfficerRegionsApiV1BulkOfficerRegionsGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bulk/officer/regions',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Officer
     * @param formData
     * @param sendOfficerNotification
     * @param validationOnly
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createOfficerApiV1BulkOfficerPost(
        formData: Body_create_officer_api_v1_bulk_officer_post,
        sendOfficerNotification: boolean = false,
        validationOnly: boolean = true,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/bulk/officer',
            query: {
                'send_officer_notification': sendOfficerNotification,
                'validation_only': validationOnly,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Download Customer Template
     * @returns any Successful Response
     * @throws ApiError
     */
    public static downloadCustomerTemplateApiV1BulkCustomerTemplateGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bulk/customer/template',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Customers
     * @param formData
     * @param validationOnly
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createCustomersApiV1BulkCustomerPost(
        formData: Body_create_customers_api_v1_bulk_customer_post,
        validationOnly: boolean = true,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/bulk/customer',
            query: {
                'validation_only': validationOnly,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Download Industry Mapping Endpoint
     * @returns any Successful Response
     * @throws ApiError
     */
    public static downloadIndustryMappingEndpointApiV1BulkIndustryMappingGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bulk/industry/mapping',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Download Contact Template
     * @returns any Successful Response
     * @throws ApiError
     */
    public static downloadContactTemplateApiV1BulkContactTemplateGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bulk/contact/template',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Contacts
     * @param formData
     * @param validationOnly
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createContactsApiV1BulkContactPost(
        formData: Body_create_contacts_api_v1_bulk_contact_post,
        validationOnly: boolean = true,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/bulk/contact',
            query: {
                'validation_only': validationOnly,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Download Certification Template
     * @returns any Successful Response
     * @throws ApiError
     */
    public static downloadCertificationTemplateApiV1BulkCertificationTemplateGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bulk/certification/template',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Download Certification Mapping Endpoint
     * @returns any Successful Response
     * @throws ApiError
     */
    public static downloadCertificationMappingEndpointApiV1BulkCertificationMappingGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bulk/certification/mapping',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Certifications
     * @param formData
     * @param validationOnly
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createCertificationsApiV1BulkCertificationPost(
        formData: Body_create_certifications_api_v1_bulk_certification_post,
        validationOnly: boolean = true,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/bulk/certification',
            query: {
                'validation_only': validationOnly,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Download License Template
     * @returns any Successful Response
     * @throws ApiError
     */
    public static downloadLicenseTemplateApiV1BulkLicenseTemplateGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bulk/license/template',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Download License Mapping Endpoint
     * @returns any Successful Response
     * @throws ApiError
     */
    public static downloadLicenseMappingEndpointApiV1BulkLicenseMappingGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bulk/license/mapping',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Licenses
     * @param formData
     * @param validationOnly
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createLicensesApiV1BulkLicensePost(
        formData: Body_create_licenses_api_v1_bulk_license_post,
        validationOnly: boolean = true,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/bulk/license',
            query: {
                'validation_only': validationOnly,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Download Holiday Template
     * @returns any Successful Response
     * @throws ApiError
     */
    public static downloadHolidayTemplateApiV1BulkHolidayTemplateGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bulk/holiday/template',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Holidays
     * @param formData
     * @param validationOnly
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createHolidaysApiV1BulkHolidayPost(
        formData: Body_create_holidays_api_v1_bulk_holiday_post,
        validationOnly: boolean = true,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/bulk/holiday',
            query: {
                'validation_only': validationOnly,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Download Shift Template
     * @returns any Successful Response
     * @throws ApiError
     */
    public static downloadShiftTemplateApiV1BulkShiftTemplateGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bulk/shift/template',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Shifts
     * @param formData
     * @param validationOnly
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createShiftsApiV1BulkShiftPost(
        formData: Body_create_shifts_api_v1_bulk_shift_post,
        validationOnly: boolean = true,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/bulk/shift',
            query: {
                'validation_only': validationOnly,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Download Contract Template
     * @returns any Successful Response
     * @throws ApiError
     */
    public static downloadContractTemplateApiV1BulkContractTemplateGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bulk/contract/template',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Contract
     * @param formData
     * @param validationOnly
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateContractApiV1BulkContractPost(
        formData: Body_update_contract_api_v1_bulk_contract_post,
        validationOnly: boolean = true,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/bulk/contract',
            query: {
                'validation_only': validationOnly,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Bulk Validate Addresses
     * @param email
     * @returns string Successful Response
     * @throws ApiError
     */
    public static bulkValidateAddressesApiV1BulkValidateAddressesPost(
        email: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/bulk/validate_addresses',
            query: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
