import { toast } from 'react-toastify'
import useSWR from 'swr'
import { ScopedMutator } from 'swr/_internal'
import useSWRImmutable from 'swr/immutable'
import {
    Firm,
    FirmAddon,
    FirmBillingUpdate,
    FirmForPayments,
    FirmPaymentSetting,
    FirmPaymentSettingUpdate,
    FirmService,
    FirmWithAddons,
} from '../generated'

import { FunctionStatusType } from './sharedTypes'

type LoadFirmFuncType = {
    firm: Firm | undefined
} & FunctionStatusType

type LoadFirmWithAddOnsFuncType = {
    firm: FirmWithAddons | undefined
} & FunctionStatusType

type LoadAvailableAddOnsFuncType = {
    addons: (
        | 'CHECKHQ_PAYROLL'
        | 'EMPLOYEE_ONBOARDING'
        | 'PAYMENT_ACCEPTANCE'
        | 'PATROLS_FEAT'
        | 'CLASP_SYNC'
        | 'BENEFITS_ADMIN'
    )[]
} & FunctionStatusType

type LoadFirmForPaymentsFuncType = {
    firm: FirmForPayments | undefined
} & FunctionStatusType

type LoadFirmPaymentSettingsFuncType = {
    firmPaymentSetting: FirmPaymentSetting | undefined
} & FunctionStatusType

type LoadFirmType = () => LoadFirmFuncType
type LoadFirmWithAddonsType = () => LoadFirmWithAddOnsFuncType
type LoadAvailableAddonsType = () => LoadAvailableAddOnsFuncType
type LoadFirmForPaymentsType = () => LoadFirmForPaymentsFuncType
type LoadFirmPaymentSettingsType = () => LoadFirmPaymentSettingsFuncType

export const useLoadFirm: LoadFirmType = () => {
    const { data, error } = useSWR(`/firm/`, () =>
        FirmService.readFirmApiV1FirmGet()
    )
    return {
        firm: data,
        isLoading: !error && !data,
        isError: error,
    }
}
export const useLoadFirmWithAddons: LoadFirmWithAddonsType = () => {
    const { data, error } = useSWRImmutable(`/firm_with_addons/`, () =>
        FirmService.readFirmWithAddonsApiV1FirmWithAddonsGet()
    )
    return {
        firm: data,
        isLoading: !error && !data,
        isError: error,
    }
}
export const useLoadFirmForPayments: LoadFirmForPaymentsType = () => {
    const { data, error } = useSWR(`/firm_for_payments/`, () =>
        FirmService.readFirmForPaymentsApiV1FirmForPaymentsGet()
    )
    return {
        firm: data,
        isLoading: !error && !data,
        isError: error,
    }
}
export const useLoadFirmPaymentSetting: LoadFirmPaymentSettingsType = () => {
    const { data, error } = useSWR(`/firm_payment_settings/`, () =>
        FirmService.readFirmPaymentSettingsApiV1FirmPaymentSettingsGet()
    )
    return {
        firmPaymentSetting: data,
        isLoading: !error && !data,
        isError: error,
    }
}

export const useLoadAvailableAddons: LoadAvailableAddonsType = () => {
    const { data, error } = useSWR(`/available_addons/`, () =>
        FirmService.readAvailableAddonsApiV1FirmAvailableAddonsGet()
    )
    return {
        addons: data ?? [],
        isLoading: !error && !data,
        isError: error,
    }
}

type UpdateFirmPaymentAcceptanceSettingFunc = (
    updateFirmPaymentSetting: FirmPaymentSettingUpdate,
    mutate: any
) => any
export const updateFirmPaymentSetting: UpdateFirmPaymentAcceptanceSettingFunc =
    async (updateFirmPaymentSetting, mutate) => {
        mutate(`/update_firm_payment_acceptance_setting/`, () =>
            FirmService.updateFirmPaymentSettingsApiV1FirmPaymentSettingsPut(
                updateFirmPaymentSetting
            )
                .catch((reason) =>
                    toast.error(reason?.body?.detail || reason?.message, {
                        autoClose: false,
                    })
                )
                .then(() => {
                    toast.success('Global Payment Acceptance Settings Saved', {
                        autoClose: 2000,
                    })
                })
        )
    }

type UpdateFirmSuspendedFunc = (
    firmId: string,
    suspended: boolean,
    mutate: ScopedMutator
) => Promise<string | number | void | undefined>
export const updateFirmSuspended: UpdateFirmSuspendedFunc = async (
    firmId,
    suspended,
    mutate
) => {
    return mutate(`/firm/`, () =>
        FirmService.updateFirmSuspendedApiV1FirmFirmIdSuspendedPut(
            firmId,
            suspended
        )
            .then(() => {
                toast.success(
                    suspended ? 'Firm suspended' : 'Firm unsuspended',
                    {
                        autoClose: 2000,
                    }
                )
            })
            .catch((reason) =>
                toast.error(reason?.body?.detail || reason?.message, {
                    autoClose: false,
                })
            )
    )
}

type UpdateFirmHubspotFunc = (
    firmId: string,
    firmHubspotId: string,
    mutate: any
) => any
export const updateFirmHubspot: UpdateFirmHubspotFunc = async (
    firmId,
    firmHubspotId,
    mutate
) => {
    mutate(`/firm_for_payments/`, () =>
        FirmService.updateFirmHubspotApiV1FirmFirmIdHubspotPut(
            firmId,
            firmHubspotId
        )
            .then(() => {
                toast.success('Hubspot id updated', {
                    autoClose: 2000,
                })
            })
            .catch((reason) =>
                toast.error(reason?.body?.detail || reason?.message, {
                    autoClose: false,
                })
            )
    )
}
type UpdateFirmBillingFunc = (
    firmId: string,
    firmUpdateBilling: FirmBillingUpdate,
    mutate: any
) => any
export const updateFirmBillingInfo: UpdateFirmBillingFunc = async (
    firmId,
    firmUpdateBilling,
    mutate
) => {
    mutate(`/firm_for_payments/`, () =>
        FirmService.updateFirmBillingApiV1FirmFirmIdBillingPut(
            firmId,
            firmUpdateBilling
        ).catch((reason) =>
            toast.error(reason?.body?.detail || reason?.message, {
                autoClose: false,
            })
        )
    )
}

type EnableAddonFunc = (
    addonKind:
        | 'CHECKHQ_PAYROLL'
        | 'EMPLOYEE_ONBOARDING'
        | 'PAYMENT_ACCEPTANCE'
        | 'PATROLS_FEAT'
        | 'CLASP_SYNC'
        | 'BENEFITS_ADMIN',
    mutate: any
) => any
export const enableAddonForFirm: EnableAddonFunc = async (
    addonKind,
    mutate
) => {
    mutate(`/firm_with_addons/`, () =>
        FirmService.enableAddonForFirmApiV1FirmEnableAddonPost(addonKind).catch(
            (reason) =>
                toast.error(reason?.body?.detail || reason?.message, {
                    autoClose: false,
                })
        )
    )
}

export function doesFirmHaveAddon(
    firm: FirmWithAddons,
    addOnKind: FirmAddon.addon_kind
): boolean {
    if (!firm.addons || !firm.addons.length) {
        return false
    }
    return (
        firm.addons.findIndex((addon) => addon.addon_kind === addOnKind) !== -1
    )
}

export function doesFirmHavePayrollAddonHelper(firm: FirmWithAddons): boolean {
    return doesFirmHaveAddon(firm, FirmAddon.addon_kind.CHECKHQ_PAYROLL)
}

export function doesFirmHavePaymentsAddonHelper(firm: FirmWithAddons): boolean {
    return doesFirmHaveAddon(firm, FirmAddon.addon_kind.PAYMENT_ACCEPTANCE)
}
export function doesFirmHavePatrolsAddonHelper(firm: FirmWithAddons): boolean {
    return doesFirmHaveAddon(firm, FirmAddon.addon_kind.PATROLS_FEAT)
}

export function doesFirmHaveBenefitsAdminAddonHelper(
    firm: FirmWithAddons
): boolean {
    return doesFirmHaveAddon(firm, FirmAddon.addon_kind.BENEFITS_ADMIN)
}
