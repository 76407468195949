import { useMemo } from 'react'
import {
    FEATURE_GATE,
    Features,
    FEATURED_ROLLOUT_TO_ALL_FIRMS,
} from '../constants/previewEligibleOrgs'
import { useLoadUser } from 'src/services/user'

export const usePreviewEligible = (feature: Features) => {
    const { user } = useLoadUser()

    const eligible = useMemo<boolean>(() => {
        if (FEATURED_ROLLOUT_TO_ALL_FIRMS[feature]) {
            return true
        }
        return (
            (user?.firm_id &&
                FEATURE_GATE[feature] &&
                FEATURE_GATE[feature].includes(user?.firm_id)) ||
            false
        )
    }, [user?.firm_id, feature])

    return eligible
}
