import { PartialRecord } from 'src/types/utilityTypes'

export const TEST_ORG_DEVO = '01411827-bfe8-4c41-ab44-ec594c4670a8'
export const TEST_ORG_PROD = '01411827-bfe8-4c41-ab44-ec594c4670a8'
export const DEMO_ONE_ORG = '87411c28-6965-4a25-bb59-9bd15958850c'
export const DEMO_TWO_ORG = '4e1e458e-155f-450b-aaae-2ebcf613f48e'

const _24_7_SECURITY_PROD = '2240e3ff-d2d0-4152-889a-2bb454de8d29'
const A_AND_A_PROD = '037cf4e7-3815-4c75-99de-1c15c7dc68ad'
const ACE_PRIVATE_SECURITY_PROD = '3fcbce15-ecfa-4a80-8515-3de467ea1633'
const ARUX_PROD = 'b4c2648d-f8ed-4659-ab88-409b71790958'
const CENTURION_PROD = '6f55992d-74a4-4ece-a13b-988906750e45'
const COMMAND_PROD = 'deb9c026-7a8d-4644-8730-6acef907ed21'
const COVEY_PROD = '4cc8308b-d375-4569-a6e5-423d563e617c'
const ENC_PROTECTION_PROD = '3f89a1f0-409b-4de5-b058-ea658cbf7a4e'
const ENHANCED_PATROL_PROD = 'b11a36e5-81a3-4e12-8841-48e83324f874'
const ETERNAL_SECURITY_PROD = 'c61bb625-4ab5-4325-97de-2130401614a3'
const EXECUTIVE_SENTINELS = 'bd32aefd-5c89-41f9-8e72-e883e9573be5'
const FULL_ARMOR_PROTECTION_GROUP = '3ce97afc-72e1-4fff-a552-ef51a385bc84'
const GREENLIGHT_PROD = '9729122e-cc3a-4c7f-9e0d-2c9fa81f6d1d'
const GUARDIEX_PROD = '2b2553b4-015c-4fe4-add5-5ce283004063'
const HOWE_PROD = '151e893e-218d-43ce-bcfe-6b2b1c77ed25'
const INDUSTRY_PROD = '88b054fa-461a-497c-a087-9194f50a50a6'
const JOHNSON_PROD = '12b30997-7b41-460c-a2c1-b32e5544fdf6'
const KRE_PROD = '0a7cff41-44cc-4df6-a44a-d78818fce83e'
const LINC_RO_PROD = '796f3551-0b02-4223-9eb5-d3ce61b84cfa'
const MERCHANTS_PROD = '49b85de6-97fc-4f4a-91da-e608e47d3401'
const MIDDLE_TENNESSEE_TRAFFIC_PROD = 'f8b622b0-2781-47f9-8a88-058ddf94d22c'
const MILLER_PROD = '3435c83c-ef62-4f60-b63d-ce4102cc0ed6'
const MPI_PROTECTIVE_PROD = '7fe2c175-cafb-459e-acad-b5f27cdf8d5d'
const NAIAS_PROD = '9f5c6b0d-2d6f-44a0-bd61-7cf08c07f51c'
const NEXT_LEVEL_PROD = '56ae1e15-76af-4f9b-8786-805a9440d9c2'
const NOLA_PROTECTION_PROD = '0c1f5a08-d7e2-4019-a94c-b26b1f1d43a8'
const N_TACT_PROD = '01cee0f0-d2a1-427d-bf44-0bffe25a314e'
const OMNIUM_PROD = '7d292ee3-194e-4648-8bed-fff67108d179'
const OVERWATCH_PROD = '350b0536-a659-4f8c-8c4c-6b31f804b97c'
const PHOENIX_PROD = 'a3ba24d7-14ad-4386-b76e-381a87366336'
const PM_SECURITY_PROD = 'd6c80629-1cb8-4a50-bf8d-43caabd6b534'
const PRICE_PROTECTIVE_PROD = '1c2708bf-e8ba-4cb8-8425-b44393f324a4'
const PRIME_PROTECTION_PROD = '275e8a06-cc1f-4c43-b017-255bc6bbac2c'
const PRO_SECURITY_PROD = 'f32a0b56-e8ed-4420-8376-677df129bdc9'
const PROFESSIONAL_PROTECTION_PROD = '5201b7f2-ae93-4601-b46b-72d8344bbb0d'
const RMS_PROTECTIVE_PROD = '5cd1186f-fdb1-40e8-a63c-0f74d8b48cbd'
const RS_DEFENSE_PROD = 'ebc87115-2bcd-4ef3-a950-ece44c296a09'
const RESTORATION_PROD = '46a7b886-0991-46c6-a7cd-7b72c36e537e'
const SEASIDE_PROD = '3f5f6819-c80b-428d-9675-cc23fe861622'
const SALIENT_DEFENSE_PROD = 'd70be0e8-2e9b-493b-9f26-1ee30d6ce69c'
const SALIENT_OPERATIONS_PROD = 'e15341c7-23be-4253-8ca3-f7b722422273'
const SHEEPDOG_PROD = 'd708f312-734a-4a78-9e8d-d4f77f24587d'
const SMR_PROD = '456fea8e-7902-4e17-b80d-1b58c5a024f8'
const STARK_SECURITY_PROD = '49801258-bd3e-4aca-99d5-790d03317e75'
const STATEWIDE_PROD = '57dd316f-c1f4-49ea-9497-6bfc662339cd'
const SUN_CITY_PROD = '6f12a585-dff4-4b3e-8878-b2904eb437db'
const TASK_FORCE_PROD = '685d87bc-b155-40d4-9203-83fff42bdf26'
const TRESTON_PROD = '4793f28e-8290-4bf7-b1f1-73f3644cdaae'
const TRIAD_PROD = '5f41f619-385d-4a46-be29-c4215e1dbf76'
const TRITON_PROD = 'ffedfc43-162b-48da-b116-1efdd321b1c0'
const SHAW_MANAGEMENT_PROD = '6f2121a9-a4fb-4a97-ae17-0b8c4d28a5d2'
const CONVOY_GROUP_PROD = '87cfc320-d8df-4bbf-bfdc-8394983504a1'
const SECURITY_PRO_INTEL_PROD = '6eba008a-257c-4949-9b04-7ad9b7fe6e5b'
const SEMPER_SECURE_PROD = '078c5ac0-3812-4cd4-a64d-98ce1ba74503'
const SOUND_SECURITY_PROD = 'e65dc163-5660-4b4e-97ec-d60fbb2a53ae'
const TEXAS_VETERAN_PROD = '3d2645e3-1fae-4b03-b075-98a3ffb6d296'
const ETERNAL_SECURITY_SERVICES_PROD = 'c61bb625-4ab5-4325-97de-2130401614a3'
const FORTIS_SECURITY_PROD = '0979cdc4-eec7-4993-ae8e-7a8127d7e10f'
const MARSHALL_SECURITY_LLC_PROD = '211b1ca7-f39d-46c7-9038-30389dc3d287'
const UCE_PROD = 'fb533c8e-9f9c-4034-b6bf-8d6b4bc997a3'
const MPI_PROD = '7fe2c175-cafb-459e-acad-b5f27cdf8d5d'
const PRINCIPAL_INTEREST_PROD = '32b91a99-963d-4e4f-89ce-2a5ab2c3843d'
const ZAHAV_PROD = '47766047-87b8-45c8-b9ef-bb3941c66358'
const BLUELINE_SECURITY_SERVICES = 'daa13e2a-dded-43d4-a1e5-0c0ed94e1100'
const PUBLIC_SAFETY_SERVICES_LLC = '2ad2c809-a7d6-48b8-a6e1-17e903b62ddd'

const BELFRY_ORGS: string[] = [TEST_ORG_DEVO, TEST_ORG_PROD]
const PAYROLL_V2_ORGS: string[] = [DEMO_ONE_ORG]

const FRIENDLY_ORGS: string[] = [
    COMMAND_PROD,
    COVEY_PROD,
    CENTURION_PROD,
    MPI_PROTECTIVE_PROD,
    NEXT_LEVEL_PROD,
    PROFESSIONAL_PROTECTION_PROD,
    SMR_PROD,

    OVERWATCH_PROD,
    GUARDIEX_PROD,
    ETERNAL_SECURITY_PROD,
    CONVOY_GROUP_PROD,
    MERCHANTS_PROD,
    MIDDLE_TENNESSEE_TRAFFIC_PROD,

    ENC_PROTECTION_PROD,
    SEMPER_SECURE_PROD,
    SOUND_SECURITY_PROD,
    RESTORATION_PROD,

    TEXAS_VETERAN_PROD,
    PRIME_PROTECTION_PROD,
    LINC_RO_PROD,
    SEASIDE_PROD,
    TRIAD_PROD,
    ENHANCED_PATROL_PROD,
]

const TIMEKEEPING_V2_FIRMS: string[] = [
    COMMAND_PROD,
    CENTURION_PROD,
    MERCHANTS_PROD,
    COVEY_PROD,
    MPI_PROD,
    NEXT_LEVEL_PROD,
    PROFESSIONAL_PROTECTION_PROD,
    SMR_PROD,
    PRINCIPAL_INTEREST_PROD,
    ZAHAV_PROD,
    BLUELINE_SECURITY_SERVICES,
    PUBLIC_SAFETY_SERVICES_LLC,
]

const DEMO_ORGS: string[] = [DEMO_ONE_ORG, DEMO_TWO_ORG]

export enum Features {
    WOTC = 'WOTC',
    MASS_ANNOUNCEMENTS = 'MASS_ANNOUNCEMENTS',
    AD_HOC_INV_START_BYPASS = 'AD_HOC_INV_START_BYPASS',
    TIME_OFF_SPBS = 'TIME_OFF_SPBS',
    TIMEKEEPING_V2 = 'TIMEKEEPING_V2',
    WAGE_BASED_TIMEKEEPING = 'WAGE_BASED_TIMEKEEPING',
    CUSTOM_INCIDENT_TYPES = 'CUSTOM_INCIDENT_TYPES',
    PAYROLL_V2 = 'PAYROLL_V2',
    PAYROLL_V2_SIDE_BY_SIDE = 'PAYROLL_V2_SIDE_BY_SIDE',
    CONTRACTOR_DEDUCTIONS = 'CONTRACTOR_DEDUCTIONS',
    ACCOUNTS_PAYABLE = 'ACCOUNTS_PAYABLE',
    ACCOUNTS_RECEIVABLE = 'ACCOUNTS_RECEIVABLE',
    TIME_OFF_JOURNAL = 'TIME_OFF_JOURNAL',
    TIME_OFF_POLICY_PREVIEW = 'TIME_OFF_POLICY_PREVIEW',
    INVENTORY = 'INVENTORY',
    DISPATCH = 'DISPATCH',
    // TODO(rdylan): Remove after soak time of ~2 weeks (2024-07-14)
    POSTORDER_API_V2 = 'POSTORDER_API_V2',
    MFA = 'MFA',
    STRICT_INVOICE_STATUSES = 'STRICT_INVOICE_STATUSES',
    FIRM_SERVICE_RATE_CLASS = 'FIRM_SERVICE_RATE_CLASS',
}
export type FeatureGate = PartialRecord<Features, string[]>
export const FEATURE_GATE = {
    [Features.WOTC]: [...BELFRY_ORGS, STARK_SECURITY_PROD, ...DEMO_ORGS],
    [Features.MASS_ANNOUNCEMENTS]: [...BELFRY_ORGS, ...DEMO_ORGS],
    [Features.AD_HOC_INV_START_BYPASS]: [
        ...BELFRY_ORGS,
        SUN_CITY_PROD,
        A_AND_A_PROD,
        ...DEMO_ORGS,
    ],
    [Features.TIME_OFF_SPBS]: [...BELFRY_ORGS, ...DEMO_ORGS],
    [Features.TIMEKEEPING_V2]: [
        ...BELFRY_ORGS,
        ...TIMEKEEPING_V2_FIRMS,
    ] as string[],
    [Features.TIME_OFF_JOURNAL]: [...BELFRY_ORGS],
    [Features.TIME_OFF_POLICY_PREVIEW]: [] as string[],
    [Features.WAGE_BASED_TIMEKEEPING]: [...BELFRY_ORGS] as string[],
    [Features.CUSTOM_INCIDENT_TYPES]: [
        ...BELFRY_ORGS,
        GREENLIGHT_PROD,
        OVERWATCH_PROD,
        FORTIS_SECURITY_PROD,
        MARSHALL_SECURITY_LLC_PROD,
        ...DEMO_ORGS,
        ...FRIENDLY_ORGS,
    ] as string[],
    // Organization in this flag get full Payroll V2 experience without any
    // references to V1.
    [Features.PAYROLL_V2]: [...PAYROLL_V2_ORGS] as string[],
    // Similar to SHADOW option, with once exception that client is
    // able to see both version of payroll. This is meant for TEST orgs only,
    // to be able to compare payrolls side-by-side on the screen. PLEASE
    // YELL IF ANY ORG OTHER THAN TEST FIRMS IS ADDED!!!!!!!!
    [Features.PAYROLL_V2_SIDE_BY_SIDE]: [
        ...PAYROLL_V2_ORGS,
        TEST_ORG_PROD,
    ] as string[],
    [Features.CONTRACTOR_DEDUCTIONS]: [...PAYROLL_V2_ORGS] as string[],
    [Features.ACCOUNTS_PAYABLE]: [...BELFRY_ORGS, ...DEMO_ORGS] as string[],
    [Features.ACCOUNTS_RECEIVABLE]: [...BELFRY_ORGS] as string[],
    [Features.INVENTORY]: [
        ...BELFRY_ORGS,
        ...DEMO_ORGS,
        RS_DEFENSE_PROD,
    ] as string[],
    [Features.DISPATCH]: [
        ...BELFRY_ORGS,
        EXECUTIVE_SENTINELS,
        FULL_ARMOR_PROTECTION_GROUP,
        MPI_PROTECTIVE_PROD,
    ],
    [Features.POSTORDER_API_V2]: [...BELFRY_ORGS, ...DEMO_ORGS],
    [Features.MFA]: [...BELFRY_ORGS, ...DEMO_ORGS] as string[],
    [Features.STRICT_INVOICE_STATUSES]: [
        ...BELFRY_ORGS,
        ...FRIENDLY_ORGS,
        A_AND_A_PROD,
        HOWE_PROD,
        SUN_CITY_PROD,
        PHOENIX_PROD,
        RMS_PROTECTIVE_PROD,
        GREENLIGHT_PROD,
        _24_7_SECURITY_PROD,
    ],

    [Features.FIRM_SERVICE_RATE_CLASS]: [] as string[],
}

export const FEATURED_ROLLOUT_TO_ALL_FIRMS: PartialRecord<Features, boolean> =
    {}

export const INTERNAL_USERS = [
    // 'auth0|62cb212df2c735713aef0f35', // Alex
    'auth0|62eab5b3216efec017dea581', // hello@
    'auth0|6532aed6e475da095b945e65', // hello1@
    'auth0|6573a359bf8a286ca6ff8b3d', // hello2@
    'auth0|65b41d4375425212b4ea1597', // hello3@
    'auth0|661554a7aa70f7f377dc7905', // hello4
    'auth0|669a9029ceb2563e0da12754', // hello5
    'auth0|66db5ab675b7a20957387178', // hello6
]

export const INTERNAL_FIRMS = [
    '01411827-bfe8-4c41-ab44-ec594c4670a8', // BelfryTest
    'a0d9a84e-2c83-4450-8e06-a399f5716099', // Dev
    '87411c28-6965-4a25-bb59-9bd15958850c', // Demo One
    '4e1e458e-155f-450b-aaae-2ebcf613f48e', // Demo Two
    '2ddf29c6-064f-4d6e-8aad-8f04f0353466', // Belfry Training
]
