import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
    interface TypographyVariants {
        dashboard: React.CSSProperties
        dashboardSecondary: React.CSSProperties
        dashboardBold: React.CSSProperties
        dashboardLink: React.CSSProperties
        cardMedium: React.CSSProperties
        body1Bold: React.CSSProperties
        body2Bold: React.CSSProperties
        captionBold: React.CSSProperties
        pill: React.CSSProperties
        smallDetail: React.CSSProperties
    }

    interface TypographyVariantsOptions {
        dashboard?: React.CSSProperties
        dashboardSecondary?: React.CSSProperties
        dashboardBold?: React.CSSProperties
        dashboardLink?: React.CSSProperties
        cardMedium?: React.CSSProperties
        body1Bold?: React.CSSProperties
        body2Bold?: React.CSSProperties
        captionBold?: React.CSSProperties
        pill?: React.CSSProperties
        smallDetail?: React.CSSProperties
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        dashboard: true
        dashboardSecondary: true
        dashboardBold: true
        dashboardLink: true
        cardMedium: true
        body1Bold: true
        body2Bold: true
        captionBold: true
        pill: true
        smallDetail: true
    }
}

const defaultTransitionDuration = 500

const softerWhite = '#FEFEFE'
const primaryBlue = '#253A8E'
const lightPrimaryBlue = 'rgba(38, 53, 147, 0.08)'
// TODO: change indigos to blues to match updated theming after syncing themes
const secondaryBlue = '#4338CA'
const darkPrimaryBlue = '#1E317F'
const serendipitousPink = '#C9347A'
const darkInactive = '#9CA3AF'
const successGreen = '#609463'
const lightSuccessGreen = 'rgba(100, 218, 44, 0.12)'
const pendingYellow = '#d99544'
const lightPendingYellow = 'rgba(217, 149, 68, 0.12)'
const warningRed = '#a83d73'
const lightWarningRed = 'rgba(217, 61, 115, 0.12)'
const errorRed = '#d32f2f'
const lightErrorRed = 'rgba(211, 47, 47, 0.12)'
const checkpointBlue = '#2e4595'
const lightCheckpointBlue = 'rgba(46, 69, 149, 0.12)'
const defaultGray = '#4b4b4b'
const lightDefaultGray = 'rgba(129, 136, 157, 0.12)'
const lightGray = '#d9d9d9'
const darkGray = '#404040'
const hoverGray = '#f5f5f5'
const lightGrayBlue = 'rgba(156, 163, 175, 0.32)'
const muiTextColor = 'rgba(0, 0, 0, 0.87)'
const backgroundButtonBlue = 'rgba(28, 53, 147, 0.3)'
const textColor = '#767676'

const successChipColor = '#d8fbd2'

// radius
const xlargeRadius = 8
const largeRadius = 6
const radius = 5

declare module '@mui/material/styles/createPalette' {
    interface Palette {
        primarySoft: PaletteColorOptions
        accent: PaletteColorOptions
        nav: Palette['primary']
        icons: {
            success: Palette['primary']
            warning: Palette['primary']
            info: Palette['primary']
            checkpoint: Palette['primary']
            default: Palette['primary']
            error: Palette['primary']
        }
        border: Palette['primary']
        hover: Palette['primary']
        darkText: Palette['primary']
        inactive: Palette['primary']
    }
    interface PaletteOptions {
        primarySoft: PaletteColorOptions
        accent: PaletteColorOptions
        nav: PaletteColorOptions
        icons?: {
            success?: PaletteColorOptions
            warning?: PaletteColorOptions
            info?: PaletteColorOptions
            checkpoint?: PaletteColorOptions
            default?: PaletteColorOptions
            error?: PaletteColorOptions
        }
        border?: PaletteColorOptions
        hover?: PaletteColorOptions
        darkText?: PaletteColorOptions
        inactive?: PaletteColorOptions
    }

    interface TypeBackground {
        focused: string
        paper: string
        default: string
    }
}
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        accent: true
        primarySoft: true
        active: true
    }
    interface ButtonPropsSizeOverrides {
        xlarge: true
    }
}

declare module '@mui/material/Radio' {
    interface RadioPropsColorOverrides {
        accent: true
    }
}

declare module '@mui/material/Chip' {
    interface ChipPropsVariantOverrides {
        active: true
    }
}

const newBelfryTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: primaryBlue,
        },
        primarySoft: {},
        secondary: {
            main: secondaryBlue,
        },
        accent: {
            main: serendipitousPink,
        },
        nav: {
            main: primaryBlue,
            contrastText: softerWhite,
        },
        icons: {
            success: {
                main: successGreen,
                light: lightSuccessGreen,
            },
            warning: {
                main: warningRed,
                light: lightWarningRed,
            },
            info: {
                main: pendingYellow,
                light: lightPendingYellow,
            },
            checkpoint: {
                main: checkpointBlue,
                light: lightCheckpointBlue,
            },
            default: {
                main: defaultGray,
                light: lightDefaultGray,
            },
            error: {
                main: errorRed,
                light: lightErrorRed,
            },
        },
        border: {
            main: lightGray,
        },
        hover: {
            main: hoverGray,
        },
        darkText: {
            main: darkGray,
        },
        inactive: {
            main: darkInactive,
        },
        background: {
            paper: softerWhite,
            focused: '#f7efbc',
        },
    },
    typography: { fontFamily: 'Inter, Manrope' },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    display: 'block',
                },
            },
            variants: [
                {
                    props: { variant: 'h1' },
                    style: {
                        fontWeight: 500,
                        fontSize: '96px',
                    },
                },
                {
                    props: { variant: 'h2' },
                    style: {
                        fontWeight: 500,
                        fontSize: '72px',
                    },
                },
                {
                    props: { variant: 'h3' },
                    style: {
                        fontWeight: 500,
                        fontSize: '48px',
                    },
                },
                {
                    props: { variant: 'h4' },
                    style: {
                        fontWeight: 500,
                        fontSize: '34px',
                    },
                },
                {
                    props: { variant: 'h5' },
                    style: {
                        fontWeight: 500,
                        fontSize: '24px',
                    },
                },
                {
                    props: { variant: 'h6' },
                    style: {
                        fontWeight: 500,
                        fontSize: '20px',
                    },
                },
                {
                    props: { variant: 'subtitle1' },
                    style: {
                        fontSize: '16px',
                    },
                },
                {
                    props: { variant: 'subtitle2' },
                    style: {
                        fontWeight: 500,
                        fontSize: '14px',
                    },
                },
                {
                    props: { variant: 'body1' },
                    style: {
                        fontSize: '16px',
                    },
                },
                {
                    props: { variant: 'body1Bold' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: '16px',
                    },
                },
                {
                    props: { variant: 'body2' },
                    style: {
                        fontSize: '14px',
                    },
                },
                {
                    props: { variant: 'body2Bold' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: '14px',
                    },
                },
                {
                    props: { variant: 'caption' },
                    style: {
                        fontSize: '12px',
                    },
                },
                {
                    props: { variant: 'captionBold' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: '12px',
                    },
                },
                {
                    props: { variant: 'overline' },
                    style: {
                        fontSize: '12px',
                    },
                },
                {
                    props: { variant: 'dashboard' },
                    style: {
                        fontSize: '12px',
                    },
                },
                {
                    props: { variant: 'dashboardSecondary' },
                    style: {
                        fontSize: '12px',
                        color: textColor,
                    },
                },
                {
                    props: { variant: 'dashboardLink' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        fontSize: '12px',
                        color: primaryBlue,
                        cursor: 'pointer',
                        '&:hover': {
                            textDecoration: 'underline',
                        },
                    },
                },
                {
                    props: { variant: 'dashboardBold' },
                    style: {
                        fontFamily: 'Inter',
                        fontWeight: 600,
                        fontSize: '12px',
                    },
                },
                {
                    props: { variant: 'cardMedium' },
                    style: {
                        fontSize: '15px',
                        textTransform: 'uppercase',
                        color: textColor,
                    },
                },
                {
                    props: { variant: 'pill' },
                    style: {
                        fontSize: '14px',
                        border: `1px solid ${lightGray}`,
                        borderRadius: '10px',
                        padding: '4px 10px',
                        color: textColor,
                    },
                },
                {
                    props: { variant: 'smallDetail' },
                    style: {
                        fontSize: '12px',
                        color: textColor,
                    },
                },
            ],
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '0.75rem',
                    fontWeight: 700,
                    color: darkInactive,
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                // this was #9CA3AF in figma but that seems very light
                root: { color: '#8e8e8e', fontWeight: 700 },
                asterisk: { color: '#E92C2C' },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: darkInactive,
                    borderRadius: xlargeRadius,
                    '& .MuiDataGrid-footerContainer': {
                        alignContent: 'center',
                    },
                    '& .MuiDataGrid-columnHeader': {
                        minHeight: '56px',
                        paddingLeft: '24px',
                        '& .MuiDataGrid-columnSeparator': {
                            visibility: 'hidden',
                        },
                    },
                    '& .MuiDataGrid-cell': {
                        paddingLeft: '24px',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                    paddingBottom: 0,
                    paddingTop: 0,
                    textTransform: 'none',
                },
            },
            variants: [
                {
                    props: { size: 'xlarge' },
                    style: {
                        fontSize: 16,
                        fontWeight: 500,
                        paddingLeft: 28,
                        paddingRight: 28,
                        paddingTop: 10,
                        paddingBottom: 10,
                        borderRadius: xlargeRadius,
                    },
                },
                {
                    props: { size: 'large' },
                    style: {
                        fontSize: 15,
                        fontWeight: 500,
                        paddingLeft: 22,
                        paddingRight: 22,
                        paddingTop: 8,
                        paddingBottom: 8,
                        borderRadius: largeRadius,
                    },
                },
                {
                    props: { size: 'medium' },
                    style: {
                        fontSize: 14,
                        fontWeight: 500,
                        paddingLeft: 16,
                        paddingRight: 16,
                        paddingTop: 6,
                        paddingBottom: 6,
                        borderRadius: radius,
                    },
                },
                {
                    props: { size: 'small' },
                    style: {
                        fontSize: 14,
                        fontWeight: 500,
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingTop: 4,
                        paddingBottom: 4,
                        borderRadius: radius,
                    },
                },
                {
                    props: { variant: 'outlined', color: 'secondary' },
                    style: {
                        backgroundColor: lightPrimaryBlue,
                        color: primaryBlue,
                        borderColor: lightGrayBlue,
                        '&:hover': {
                            backgroundColor: lightGrayBlue,
                        },
                    },
                },
                {
                    props: { variant: 'contained', color: 'primarySoft' },
                    style: {
                        backgroundColor: primaryBlue,
                        color: softerWhite,
                        '&:hover': {
                            backgroundColor: darkPrimaryBlue,
                        },
                    },
                },
                {
                    props: { variant: 'contained', color: 'secondary' },
                    style: {
                        backgroundColor: lightPrimaryBlue,
                        color: primaryBlue,
                        '&:hover': {
                            backgroundColor: lightGrayBlue,
                        },
                    },
                },
                {
                    props: { variant: 'text', color: 'secondary' },
                    style: {
                        color: primaryBlue,
                        '&:hover': {
                            backgroundColor:
                                lightPrimaryBlue /* can't currently find a figma element that corresponds to this */,
                            borderColor: lightGrayBlue,
                        },
                    },
                },
                {
                    props: { variant: 'selectable' },
                    style: {
                        borderColor: lightGray,
                        width: '200px',
                        height: '50px',
                        borderRadius: '20px',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        color: muiTextColor,
                        fontSize: '16px',
                        fontWeight: '400',
                    },
                },
                {
                    props: { variant: 'selectable', color: 'active' },
                    style: {
                        backgroundColor: backgroundButtonBlue,
                    },
                },
            ],
        },
        MuiSwitch: {
            variants: [
                {
                    props: { size: 'small' },
                    style: {
                        width: 36,
                        height: 20,
                        '& .MuiSwitch-thumb': {
                            boxSizing: 'border-box',
                            width: 15,
                            height: 15,
                        },
                    },
                },
            ],
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: 'inherit',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    color: primaryBlue,
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    borderRadius: 0,
                },
            },
        },
        MuiDivider: {
            variants: [
                {
                    props: { variant: 'fullWidthModal' },
                    style: {
                        width: '1064px',
                        marginLeft: '-32px',
                        marginRight: '-32px',
                        position: 'relative',
                        overflow: 'hidden',
                    },
                },
            ],
        },
        MuiChip: {
            variants: [
                {
                    // rdylan: This maybe should be 'success', but too many chips use 'success'
                    // with inline sx. Not going to go through and check them all.
                    props: { variant: 'active' },
                    style: {
                        backgroundColor: successChipColor,
                    },
                },
            ],
        },
    },
    zIndex: {
        drawer: 1200,
    },
})

const getThemeColor = (color: keyof typeof newBelfryTheme.palette) => {
    const paletteColor = newBelfryTheme.palette[color]
    if (typeof paletteColor === 'object' && 'main' in paletteColor) {
        return paletteColor.main
    }
    return paletteColor as string
}

export default newBelfryTheme
export { defaultTransitionDuration, getThemeColor }
