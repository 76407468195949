import { parseISO, isValid, differenceInMinutes } from 'date-fns'

import {
    BreakInstance,
    ScheduleShiftInstance,
    Settings,
    ShiftInstanceForSchedule,
    TimeOff,
    TimekeepingBreakInstance,
    WageCategory,
    WageResult,
} from '../../generated'
import {
    maybeAbbrevTZIfDiffers,
    parseAndFormatWithTZAndTimeDisplaySetting,
} from '../../utils/dateUtils'
import { nonNullable, sumArrayOfObjectsUntyped } from '../../utils/arr'
import { NewShiftInstance } from 'src/types/newShiftInstance'
import { SECONDS_IN_HOUR } from 'src/constants/dateConstants'
import {
    instance_actual_hours_reducer_v2,
    instance_sched_hours_reducer_v2,
} from '../Scheduling/ScheduleGrid/shared'

export function computeActualBreakCount(
    break_instances: BreakInstance[] | TimekeepingBreakInstance[]
) {
    return (break_instances as TimekeepingBreakInstance[]).filter(
        (instance: TimekeepingBreakInstance) =>
            instance.started && instance.ended
    ).length
}

export function getTimeHelper(
    ts: string | undefined,
    iana_timezone: string,
    use24HourClock?: boolean
) {
    if (!ts || !isValid(parseISO(ts))) {
        return 'N/A'
    }
    return (
        parseAndFormatWithTZAndTimeDisplaySetting(
            ts,
            iana_timezone,
            'h:mma',
            'HH:mm',
            use24HourClock ?? false
        ).toLowerCase() + maybeAbbrevTZIfDiffers(iana_timezone)
    )
}

export function getScheduledShiftHourTotalV1(
    shift_instance: ShiftInstanceForSchedule | ScheduleShiftInstance
) {
    const hours = shift_instance.scheduled_shift_details?.total_hours
    return typeof hours !== 'number'
        ? 'N/A'
        : `${hours.toLocaleString(undefined, {
              minimumFractionDigits: 0,
          })}h`
}

export function getScheduledShiftHourTotalV2(shift_instance: NewShiftInstance) {
    const hours = shift_instance.scheduled_length
        ? shift_instance.scheduled_length / SECONDS_IN_HOUR
        : null
    return typeof hours !== 'number'
        ? 'N/A'
        : `${hours.toLocaleString(undefined, {
              minimumFractionDigits: 0,
          })}h`
}

export function getActualShiftHourTotalV1(
    shift_instance: ShiftInstanceForSchedule | ScheduleShiftInstance
) {
    const hours = shift_instance.actual_shift_details?.total_hours
    if (hours === 0) {
        return '0h'
    }
    return typeof hours !== 'number'
        ? 'N/A'
        : `${hours.toLocaleString(undefined, {
              minimumFractionDigits: 0,
          })}h`
}

export function getActualShiftHourTotalV2(shift_instance: NewShiftInstance) {
    const hours = [shift_instance].reduce(instance_actual_hours_reducer_v2, 0)
    return typeof hours !== 'number'
        ? 'N/A'
        : `${hours.toLocaleString(undefined, {
              minimumFractionDigits: 0,
          })}h`
}
// Previously these  keys mapped to ShiftDetails, but were misleading, as total_overtime_hours was being used for single overtime hours
type PossibleSummaryKeyType =
    | 'regular_hours'
    | 'single_overtime_hours'
    | 'double_overtime_compensation_hours'
    | 'holiday_hours'
    | 'pto_hours'
    | 'sick_hours'
    | 'total_hours'
    | 'total_compensation'
type SummaryKeyLabel = {
    label: string
    key: PossibleSummaryKeyType
    denom: number
    prefix?: string
}

export const SUMMARY_KEYS_LABELS_SUPERVISOR: SummaryKeyLabel[] = [
    { label: 'regular', key: 'regular_hours', denom: 1 },
    { label: 'ot', key: 'single_overtime_hours', denom: 1 },
    { label: '2ot', key: 'double_overtime_compensation_hours', denom: 1 },
    { label: 'hol', key: 'holiday_hours', denom: 1 },
    { label: 'pto', key: 'pto_hours', denom: 1 },
    { label: 'sick', key: 'sick_hours', denom: 1 },
    { label: 'total', key: 'total_hours', denom: 1, prefix: '' },
]

export const SUMMARY_KEYS_LABELS_NON_SUPERVISOR: SummaryKeyLabel[] = [
    ...SUMMARY_KEYS_LABELS_SUPERVISOR,
    { label: 'comp', key: 'total_compensation', denom: 100, prefix: '$' },
]

export function sumTimeOffForPolicyType(
    timeOffs: TimeOff[],
    policyType: 'PTO' | 'SICK' | 'UNPAID'
) {
    return (
        timeOffs
            .filter((timeOff) => timeOff.policy_type == policyType)
            .reduce((acc, val) => acc + val.minutes_for_time_off, 0) / 60
    )
}

type PreProcessTotalType = {
    [key in PossibleSummaryKeyType]?: number
}

type PreProcessTotalsReturnType = {
    breakDifference: number
    totalHourDifference: number
    scheduledTotals: PreProcessTotalType
    actualTotals: PreProcessTotalType
}

export function filterAndReduceWageResultsToHours(
    wageCategories: WageCategory[],
    wageResults: WageResult[]
) {
    return wageResults
        .filter((a) => wageCategories.includes(a.category))
        .reduce((acc: number, cur: WageResult) => acc + cur.length, 0)
}

export function preProcessTotalsHelperV2(
    shift_instances: NewShiftInstance[],
    timeOffs: TimeOff[]
): PreProcessTotalsReturnType {
    const total_hours_scheduled_for_shifts = shift_instances.reduce(
        instance_sched_hours_reducer_v2,
        0
    )
    const total_hours_actual_for_shifts = shift_instances.reduce(
        instance_actual_hours_reducer_v2,
        0
    )

    const timeOffTotalComp = timeOffs
        .filter(
            (timeOff) =>
                timeOff.policy_type == 'SICK' || timeOff.policy_type == 'PTO'
        )
        .reduce((acc, val) => acc + val.total_time_off_comp, 0)

    const breaks = shift_instances
        .map((inst) => inst.break_instances)
        .flat()
        .filter(nonNullable)
    const actualBreakCount = computeActualBreakCount(breaks)
    const breakDifference = Math.abs(actualBreakCount - breaks.length)

    const flattenedScheduledWageResults = shift_instances
        .map((inst) => inst.scheduled_wage_results)
        .flat()
        .filter(nonNullable)

    const flattenedActualWageResults = shift_instances
        .map((inst) => inst.actual_wage_results)
        .flat()
        .filter(nonNullable)

    const pto_hours = sumTimeOffForPolicyType(timeOffs, 'PTO')
    const sick_hours = sumTimeOffForPolicyType(timeOffs, 'SICK')

    const total_hours_scheduled =
        total_hours_scheduled_for_shifts + pto_hours + sick_hours
    const total_hours_actual =
        total_hours_actual_for_shifts + pto_hours + sick_hours

    // now compute the scheduled totals
    const scheduledTotals = {
        regular_hours: filterAndReduceWageResultsToHours(
            [WageCategory.REG],
            flattenedScheduledWageResults
        ),
        single_overtime_hours: filterAndReduceWageResultsToHours(
            [WageCategory.OT],
            flattenedScheduledWageResults
        ),
        double_overtime_compensation_hours: filterAndReduceWageResultsToHours(
            [WageCategory.DT],
            flattenedScheduledWageResults
        ),
        holiday_hours: filterAndReduceWageResultsToHours(
            [WageCategory.HOL],
            flattenedScheduledWageResults
        ),
        pto_hours,
        sick_hours,
        total_hours: total_hours_scheduled,
        total_compensation:
            flattenedScheduledWageResults.reduce(
                (acc: number, cur: WageResult) => acc + cur.total,
                0
            ) + timeOffTotalComp,
    }

    const actualTotals = {
        regular_hours: filterAndReduceWageResultsToHours(
            [WageCategory.REG],
            flattenedActualWageResults
        ),
        single_overtime_hours: filterAndReduceWageResultsToHours(
            [WageCategory.OT],
            flattenedActualWageResults
        ),
        double_overtime_compensation_hours: filterAndReduceWageResultsToHours(
            [WageCategory.DT],
            flattenedActualWageResults
        ),
        holiday_hours: filterAndReduceWageResultsToHours(
            [WageCategory.HOL],
            flattenedActualWageResults
        ),
        pto_hours,
        sick_hours,
        total_hours: total_hours_actual,
        total_compensation:
            flattenedActualWageResults.reduce(
                (acc: number, cur: WageResult) => acc + cur.total,
                0
            ) + timeOffTotalComp,
    }

    const totalHourDifference = Math.abs(
        total_hours_scheduled - total_hours_actual
    )

    return {
        breakDifference,
        totalHourDifference,
        scheduledTotals,
        actualTotals,
    }
}

export function preProcessTotalsHelperV1(
    shift_instances: ScheduleShiftInstance[],
    timeOffs: TimeOff[]
) {
    const scheduledTotals = sumArrayOfObjectsUntyped(
        shift_instances
            .filter((inst) => !!inst.scheduled_shift_details)
            .map((inst) => inst.scheduled_shift_details)
    )
    // Only include breaks for completed shifts
    const scheduledCompletedTotals = sumArrayOfObjectsUntyped(
        shift_instances
            .filter((inst) => inst.clock_out)
            .filter((inst) => !!inst.scheduled_shift_details)
            .map((inst) => inst.scheduled_shift_details)
    )
    const scheduledBreaks = scheduledCompletedTotals['number_breaks']
    // There is no count of OT hours without double OT hours, so we have to dynamically calc it.
    scheduledTotals['single_overtime_hours'] =
        scheduledTotals['total_overtime_hours'] -
        scheduledTotals['double_overtime_compensation_hours']
    const actualTotals = sumArrayOfObjectsUntyped(
        shift_instances
            .filter((inst) => inst.actual_shift_details)
            .map((inst) => inst.actual_shift_details)
    )
    const actualCompletedTotals = sumArrayOfObjectsUntyped(
        shift_instances
            .filter((inst) => inst.clock_out && inst.actual_shift_details)
            .map((inst) => inst.actual_shift_details)
    )

    const actualBreaks = actualCompletedTotals['number_breaks']
    actualTotals['single_overtime_hours'] =
        actualTotals['total_overtime_hours'] -
        actualTotals['double_overtime_compensation_hours']

    // Update totals based on time offs
    scheduledTotals['pto_hours'] = sumTimeOffForPolicyType(timeOffs, 'PTO')
    scheduledTotals['sick_hours'] = sumTimeOffForPolicyType(timeOffs, 'SICK')
    scheduledTotals['unpaid'] = sumTimeOffForPolicyType(timeOffs, 'UNPAID')
    const timeOffTotalComp = timeOffs
        .filter(
            (timeOff) =>
                timeOff.policy_type == 'SICK' || timeOff.policy_type == 'PTO'
        )
        .reduce((acc, val) => acc + val.total_time_off_comp, 0)

    scheduledTotals['total_hours'] =
        scheduledTotals['total_hours'] == undefined ||
        Number.isNaN(scheduledTotals['total_hours'])
            ? scheduledTotals['pto_hours'] + scheduledTotals['sick_hours']
            : scheduledTotals['total_hours'] +
              scheduledTotals['pto_hours'] +
              scheduledTotals['sick_hours']
    scheduledTotals['total_compensation'] = scheduledTotals[
        'total_compensation'
    ]
        ? scheduledTotals['total_compensation'] + timeOffTotalComp
        : timeOffTotalComp

    actualTotals['pto_hours'] = sumTimeOffForPolicyType(timeOffs, 'PTO')
    actualTotals['sick_hours'] = sumTimeOffForPolicyType(timeOffs, 'SICK')
    actualTotals['unpaid'] = sumTimeOffForPolicyType(timeOffs, 'UNPAID')

    actualTotals['total_hours'] =
        actualTotals['total_hours'] == undefined ||
        Number.isNaN(actualTotals['total_hours'])
            ? actualTotals['pto_hours'] + actualTotals['sick_hours']
            : actualTotals['total_hours'] +
              actualTotals['pto_hours'] +
              actualTotals['sick_hours']

    actualTotals['total_compensation'] = actualTotals['total_compensation']
        ? actualTotals['total_compensation'] + timeOffTotalComp
        : timeOffTotalComp

    const breakDifference = Math.abs(actualBreaks - scheduledBreaks)
    const totalHourDifference = Math.abs(
        scheduledTotals['total_hours'] - actualTotals['total_hours']
    )
    return {
        breakDifference,
        totalHourDifference,
        scheduledTotals,
        actualTotals,
    }
}

export function checkhq_processing_period_days_to_int(
    checkhq_processing_period?: string
) {
    if (checkhq_processing_period === 'one_day') {
        return 1
    } else if (checkhq_processing_period === 'two_day') {
        return 2
    } else if (checkhq_processing_period === 'three_day') {
        return 3
    } else {
        return 4
    }
}

export function isSchedMismatchHelper(
    instance: ScheduleShiftInstance | ShiftInstanceForSchedule,
    settings: Settings
) {
    const [startDiff, endDiff] = getSchedVersusActualDiffs(instance)
    const tk_auto_approval_thresh_minutes =
        settings.tk_auto_approval_thresh_minutes || 1
    return (
        Math.abs(startDiff) > tk_auto_approval_thresh_minutes ||
        Math.abs(endDiff) > tk_auto_approval_thresh_minutes
    )
}

export function isClockInWithinThreshHelper(
    instance: ScheduleShiftInstance | ShiftInstanceForSchedule,
    settings: Settings
) {
    if (!instance.clock_in) {
        return false
    }
    const startDiff = differenceInMinutes(
        parseISO(instance.shift_start_date_time as string),
        parseISO(instance.clock_in)
    )
    const tk_auto_approval_thresh_minutes =
        settings.tk_auto_approval_thresh_minutes || 1
    return Math.abs(startDiff) < tk_auto_approval_thresh_minutes
}

function getSchedVersusActualDiffs(
    instance: ScheduleShiftInstance | ShiftInstanceForSchedule
) {
    if (
        !instance.clock_in ||
        !instance.clock_out ||
        !instance.shift_start_date_time ||
        !instance.shift_end_date_time
    ) {
        return [NaN, NaN]
    }
    const startDiff = differenceInMinutes(
        parseISO(instance.shift_start_date_time),
        parseISO(instance.clock_in)
    )
    const endDiff = differenceInMinutes(
        parseISO(instance.shift_end_date_time),
        parseISO(instance.clock_out)
    )
    return [startDiff, endDiff]
}
