/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum NotificationCategory {
    ABANDONED_POST = 'abandoned_post',
    CLOCKED_OUT_EARLY = 'clocked_out_early',
    CONTRACT_MANAGEMENT = 'contract_management',
    CURRENT_SHIFT = 'current_shift',
    DISPATCH = 'dispatch',
    EXPIRED_CERTIFICATIONS = 'expired_certifications',
    EXPIRED_LICENSES = 'expired_licenses',
    EXPIRING_CERTIFICATIONS = 'expiring_certifications',
    EXPIRING_LICENSES = 'expiring_licenses',
    MASS_ANNOUNCEMENT = 'mass_announcement',
    MISSED_TOUR = 'missed_tour',
    MISSING_CLOCK_IN = 'missing_clock_in',
    MISSING_OR_MISSED_TOUR = 'missing_or_missed_tour',
    NEW_INCIDENT = 'new_incident',
    NEW_NOTICE = 'new_notice',
    NO_RECENT_LOCATION = 'no_recent_location',
    NO_RECENT_LOCATION_ADMIN = 'no_recent_location_admin',
    SCHEDULING_OFFICERS = 'scheduling_officers',
    SHIFT_OFFER = 'shift_offer',
    SHIFT_OFFER_ADMIN = 'shift_offer_admin',
    SHIFT_OFFER_INTEREST = 'shift_offer_interest',
    TIME_OFF_REQUEST = 'time_off_request',
    TIME_OFF_REQUEST_REJECTED = 'time_off_request_rejected',
}
