/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ShiftInstanceBelltowerStatus {
    ALL_CLEAR = 'All Clear',
    PAST_CLOCK_OUT_TIME = 'Past Clock-out Time',
    INCIDENTS_REPORTED = 'Incidents Reported',
    ON_BREAK = 'On Break',
    NO_RECENT_LOCATION_UPDATES = 'No Recent Location Updates',
    NOT_ON_SITE = 'Not On Site',
    NO_ASSIGNED_OFFICER = 'No Assigned Officer',
    NOT_CLOCKED_IN = 'Not Clocked In',
    CLOCKED_OUT_EARLY = 'Clocked Out Early',
    UNKNOWN_LOCATION = 'Unknown Location',
}
