import { ScheduleShiftInstance, Settings, ShiftDetails } from 'src/generated'
import { MobiscrollShiftInstance } from 'src/types/mobiscrollShiftInstance'
import { NewShiftInstance } from 'src/types/newShiftInstance'
import { ComboShiftInstance } from '../Mobile/ShiftInstance'

export type BoxIconsType = {
    instance: MobiscrollShiftInstance
    displayKey: string
    settings?: Settings
    isOfficerCentricMode?: boolean
    onlyIcons?: boolean
}

export type ShiftInstanceWithScheduledDetails = {
    scheduled_shift_details?: ShiftDetails
}
export type EitherMobiscrollOrScheduleShiftInstance =
    | MobiscrollShiftInstance
    | NewShiftInstance

const WorkingScheduleViewIntervalKinds = ['day', 'week', 'month'] as const

export type WorkingScheduleViewIntervalKindType =
    typeof WorkingScheduleViewIntervalKinds[number]

export function isWorkingScheduleViewIntervalKindType(
    x: string
): x is WorkingScheduleViewIntervalKindType {
    return (WorkingScheduleViewIntervalKinds as readonly string[]).includes(x)
}

export function hasGroupMetadata(
    inst: ScheduleShiftInstance | ComboShiftInstance | MobiscrollShiftInstance
): inst is MobiscrollShiftInstance {
    return (inst as MobiscrollShiftInstance).group_metadata !== undefined
}

export type DayBoxInnerDisplayKeysType =
    | 'Grouped'
    | 'Open'
    | 'Unpublished'
    | 'Published'
    | 'past_mismatched_time'
    | 'past_on_time'
    | 'past_missing_punches'
    | 'actively_occurring_about_to_start'
    | 'actively_occurring_started_on_time'
    | 'actively_occurring_mismatched_time'
    | 'actively_occurring_missing_punches'
