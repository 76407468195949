/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Firm } from '../models/Firm';
import type { FirmBillingUpdate } from '../models/FirmBillingUpdate';
import type { FirmCreate } from '../models/FirmCreate';
import type { FirmCreateWithId } from '../models/FirmCreateWithId';
import type { FirmForPayments } from '../models/FirmForPayments';
import type { FirmLegalUpdate } from '../models/FirmLegalUpdate';
import type { FirmPaymentSetting } from '../models/FirmPaymentSetting';
import type { FirmPaymentSettingUpdate } from '../models/FirmPaymentSettingUpdate';
import type { FirmWithAddons } from '../models/FirmWithAddons';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FirmService {

    /**
     * Read Firm
     * Returns the details for a given firm that the user is the admin of.
     * @returns Firm Successful Response
     * @throws ApiError
     */
    public static readFirmApiV1FirmGet(): CancelablePromise<Firm> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/firm',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Firm
     * Functionality is not intended to be surfaced to users.
     * Instead used by devs during client setup.
     * @param requestBody
     * @returns Firm Successful Response
     * @throws ApiError
     */
    public static createFirmApiV1FirmPost(
        requestBody: FirmCreate,
    ): CancelablePromise<Firm> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/firm',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Firm With Addons
     * Returns the details for a given firm including addons that the user is the admin of.
     * @returns FirmWithAddons Successful Response
     * @throws ApiError
     */
    public static readFirmWithAddonsApiV1FirmWithAddonsGet(): CancelablePromise<FirmWithAddons> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/firm_with_addons',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Available Addons
     * Returns a list of all addons that can be enabled for a customer.
     * @returns string Successful Response
     * @throws ApiError
     */
    public static readAvailableAddonsApiV1FirmAvailableAddonsGet(): CancelablePromise<Array<'CHECKHQ_PAYROLL' | 'EMPLOYEE_ONBOARDING' | 'PAYMENT_ACCEPTANCE' | 'PATROLS_FEAT' | 'CLASP_SYNC' | 'BENEFITS_ADMIN'>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/firm/available_addons',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Enable Addon For Firm
     * @param addonName
     * @returns FirmWithAddons Successful Response
     * @throws ApiError
     */
    public static enableAddonForFirmApiV1FirmEnableAddonPost(
        addonName: 'CHECKHQ_PAYROLL' | 'EMPLOYEE_ONBOARDING' | 'PAYMENT_ACCEPTANCE' | 'PATROLS_FEAT' | 'CLASP_SYNC' | 'BENEFITS_ADMIN',
    ): CancelablePromise<FirmWithAddons> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/firm/enable_addon',
            query: {
                'addon_name': addonName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Firm With Id
     * Functionality is not intended to be surfaced to users.
     * Instead used by devs during client setup.
     * @param requestBody
     * @returns Firm Successful Response
     * @throws ApiError
     */
    public static createFirmWithIdApiV1FirmWithIdPost(
        requestBody: FirmCreateWithId,
    ): CancelablePromise<Firm> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/firm_with_id',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Firm
     * Update Firm object legal information
     * @param firmId
     * @param requestBody
     * @returns Firm Successful Response
     * @throws ApiError
     */
    public static updateFirmApiV1FirmFirmIdLegalPut(
        firmId: string,
        requestBody: FirmLegalUpdate,
    ): CancelablePromise<Firm> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/firm/{firm_id}/legal',
            path: {
                'firm_id': firmId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Firm Hubspot
     * Update Firm object object with hubspot details
     * @param firmId
     * @param huspotId
     * @returns Firm Successful Response
     * @throws ApiError
     */
    public static updateFirmHubspotApiV1FirmFirmIdHubspotPut(
        firmId: string,
        huspotId: string,
    ): CancelablePromise<Firm> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/firm/{firm_id}/hubspot',
            path: {
                'firm_id': firmId,
            },
            query: {
                'huspot_id': huspotId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Firm Billing
     * Update Firm object object with billing information
     * @param firmId
     * @param requestBody
     * @returns Firm Successful Response
     * @throws ApiError
     */
    public static updateFirmBillingApiV1FirmFirmIdBillingPut(
        firmId: string,
        requestBody: FirmBillingUpdate,
    ): CancelablePromise<Firm> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/firm/{firm_id}/billing',
            path: {
                'firm_id': firmId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Firm For Payments
     * Returns the details for a given firm for payments/billing.
     * @returns FirmForPayments Successful Response
     * @throws ApiError
     */
    public static readFirmForPaymentsApiV1FirmForPaymentsGet(): CancelablePromise<FirmForPayments> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/firm_for_payments',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Firm Payment Settings
     * Returns payment settings and configuration information for a firm
     * @returns FirmPaymentSetting Successful Response
     * @throws ApiError
     */
    public static readFirmPaymentSettingsApiV1FirmPaymentSettingsGet(): CancelablePromise<FirmPaymentSetting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/firm/payment/settings',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Firm Payment Settings
     * Updates any necessary firm-level payment settings
     * @param requestBody
     * @returns FirmPaymentSetting Successful Response
     * @throws ApiError
     */
    public static updateFirmPaymentSettingsApiV1FirmPaymentSettingsPut(
        requestBody: FirmPaymentSettingUpdate,
    ): CancelablePromise<FirmPaymentSetting> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/firm/payment/settings',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Firm States Of Operation
     * Returns the list of US state keys that the firm is operating in.
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getFirmStatesOfOperationApiV1GetFirmStatesGet(): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/get_firm_states',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Post Add Firm States Of Operation
     * Add states to the list of US state keys that the firm is operating in.
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static postAddFirmStatesOfOperationApiV1AddFirmStatesPost(
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/add_firm_states',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Post Remove Firm States Of Operation
     * Remove states from the list of US state keys that the firm is operating in.
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static postRemoveFirmStatesOfOperationApiV1RemoveFirmStatesPost(
        requestBody: Array<string>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/remove_firm_states',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Firm Suspended
     * @param firmId
     * @param suspended
     * @returns Firm Successful Response
     * @throws ApiError
     */
    public static updateFirmSuspendedApiV1FirmFirmIdSuspendedPut(
        firmId: string,
        suspended: boolean,
    ): CancelablePromise<Firm> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/firm/{firm_id}/suspended',
            path: {
                'firm_id': firmId,
            },
            query: {
                'suspended': suspended,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
