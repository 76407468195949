/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FirmServiceLabel } from '../models/FirmServiceLabel';
import type { FirmServiceLabelCreate } from '../models/FirmServiceLabelCreate';
import type { FirmServiceLabelUpdate } from '../models/FirmServiceLabelUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FirmServiceService {

    /**
     * Read Firm Services
     * Returns services for firm with given ID
     * @returns FirmServiceLabel Successful Response
     * @throws ApiError
     */
    public static readFirmServicesApiV1FirmServicesGet(): CancelablePromise<Array<FirmServiceLabel>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/firm/services',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Firm Service
     * Create service for firm
     * @param requestBody
     * @returns FirmServiceLabel Successful Response
     * @throws ApiError
     */
    public static createFirmServiceApiV1FirmServicePost(
        requestBody: FirmServiceLabelCreate,
    ): CancelablePromise<FirmServiceLabel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/firm/service',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Firm Service
     * Update service for firm
     * @param serviceId
     * @param requestBody
     * @returns FirmServiceLabel Successful Response
     * @throws ApiError
     */
    public static updateFirmServiceApiV1FirmServiceServiceIdPut(
        serviceId: string,
        requestBody: FirmServiceLabelUpdate,
    ): CancelablePromise<FirmServiceLabel> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/firm/service/{service_id}',
            path: {
                'service_id': serviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Firm Service
     * Delete service for firm
     * @param serviceId
     * @returns FirmServiceLabel Successful Response
     * @throws ApiError
     */
    public static deleteFirmServiceApiV1FirmServiceServiceIdDelete(
        serviceId: string,
    ): CancelablePromise<FirmServiceLabel> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/firm/service/{service_id}',
            path: {
                'service_id': serviceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
