/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BelfryAPIOfficerServiceRateCreateUpdate } from '../models/BelfryAPIOfficerServiceRateCreateUpdate';
import type { OfficerServiceRate } from '../models/OfficerServiceRate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OfficerServiceRateService {

    /**
     * Read Officer Service Rates
     * Returns services for officer with given ID
     * @param officerId
     * @returns OfficerServiceRate Successful Response
     * @throws ApiError
     */
    public static readOfficerServiceRatesApiV1OfficerOfficerIdServiceRateGet(
        officerId: string,
    ): CancelablePromise<Array<OfficerServiceRate>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/officer/{officer_id}/service_rate',
            path: {
                'officer_id': officerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Update Officer Service Rate
     * Create and update services for officer
     * @param requestBody
     * @returns string Successful Response
     * @throws ApiError
     */
    public static createUpdateOfficerServiceRateApiV1OfficerServiceRatesPost(
        requestBody: BelfryAPIOfficerServiceRateCreateUpdate,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/officer/service_rates',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
