/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { TimeOffAddedEmployee } from './TimeOffAddedEmployee';
import type { TimeOffPolicyCreate } from './TimeOffPolicyCreate';

export type TimeOffPolicyAssignmentRequest = {
    employees: Array<TimeOffAddedEmployee>;
    employee_selection_mode: TimeOffPolicyAssignmentRequest.employee_selection_mode;
    policy?: (TimeOffPolicyCreate | null);
    existing_policy_id?: (string | null);
    preview?: boolean;
};

export namespace TimeOffPolicyAssignmentRequest {

    export enum employee_selection_mode {
        SPECIFIED_ONLY = 'SPECIFIED_ONLY',
        ALL_APPLICABLE = 'ALL_APPLICABLE',
        ASSIGNED_NOW = 'ASSIGNED_NOW',
    }


}

