/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_bulk_policy_update_api_v1_time_off_policies_bulk_update__policy_id__post } from '../models/Body_bulk_policy_update_api_v1_time_off_policies_bulk_update__policy_id__post';
import type { DecomposedBalancePerPeriod } from '../models/DecomposedBalancePerPeriod';
import type { DecomposedDailyAccrual } from '../models/DecomposedDailyAccrual';
import type { DecomposedGrantedBalance } from '../models/DecomposedGrantedBalance';
import type { DecomposedHourlyAccrual } from '../models/DecomposedHourlyAccrual';
import type { NascentEmployeeForTimeOff } from '../models/NascentEmployeeForTimeOff';
import type { TimeOffAdjustment } from '../models/TimeOffAdjustment';
import type { TimeOffEmployee } from '../models/TimeOffEmployee';
import type { TimeOffPolicy } from '../models/TimeOffPolicy';
import type { TimeOffPolicyAssignmentRequest } from '../models/TimeOffPolicyAssignmentRequest';
import type { TimeOffPolicyAssignmentResponse } from '../models/TimeOffPolicyAssignmentResponse';
import type { TimeOffPolicyCreate } from '../models/TimeOffPolicyCreate';
import type { TimeOffPolicyMapping } from '../models/TimeOffPolicyMapping';
import type { TimeOffPolicyUpdate } from '../models/TimeOffPolicyUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TimeOffPolicyService {

    /**
     * Read Policies
     * @returns TimeOffPolicy Successful Response
     * @throws ApiError
     */
    public static readPoliciesApiV1TimeOffPoliciesPoliciesGet(): CancelablePromise<Array<TimeOffPolicy>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/time_off_policies/policies',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Policy
     * @param id
     * @returns TimeOffPolicy Successful Response
     * @throws ApiError
     */
    public static readPolicyApiV1TimeOffPoliciesPolicyIdGet(
        id: string,
    ): CancelablePromise<TimeOffPolicy> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/time_off_policies/policy/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Policy
     * @param id
     * @param requestBody
     * @returns TimeOffPolicy Successful Response
     * @throws ApiError
     */
    public static updatePolicyApiV1TimeOffPoliciesPolicyIdPut(
        id: string,
        requestBody: TimeOffPolicyUpdate,
    ): CancelablePromise<TimeOffPolicy> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/time_off_policies/policy/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Archive Policy
     * Preforms a soft-delete on the time off policy by setting an end-date for the policy. The policy still remains in effect
     * from its effective date to the end date set.
     * @param id
     * @param endDate
     * @returns TimeOffPolicy Successful Response
     * @throws ApiError
     */
    public static archivePolicyApiV1TimeOffPoliciesPolicyIdDelete(
        id: string,
        endDate: string,
    ): CancelablePromise<TimeOffPolicy> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/time_off_policies/policy/{id}',
            path: {
                'id': id,
            },
            query: {
                'end_date': endDate,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Employees For Policy
     * @param id
     * @returns TimeOffEmployee Successful Response
     * @throws ApiError
     */
    public static readEmployeesForPolicyApiV1TimeOffPoliciesPolicyIdEmployeesGet(
        id: string,
    ): CancelablePromise<Array<TimeOffEmployee>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/time_off_policies/policy/{id}/employees',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Policy
     * @param requestBody
     * @returns TimeOffPolicy Successful Response
     * @throws ApiError
     */
    public static createPolicyApiV1TimeOffPoliciesPolicyPut(
        requestBody: TimeOffPolicyCreate,
    ): CancelablePromise<TimeOffPolicy> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/time_off_policies/policy',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Policy
     * Preforms a hard-delete on the time off policy. Will remove all associated date so should only be
     * used for accidental time off policies.
     * @param id
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deletePolicyApiV1TimeOffPoliciesPolicyIdHardDeleteDelete(
        id: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/time_off_policies/policy/{id}/hard_delete',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Zero Policy Balance
     * Sets the balance of all officers in this policy to zero
     * @param id
     * @returns string Successful Response
     * @throws ApiError
     */
    public static zeroPolicyBalanceApiV1TimeOffPoliciesPolicyIdZeroBalancePut(
        id: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/time_off_policies/policy/{id}/zero_balance',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Policies For Employee
     * @param employeeId
     * @returns TimeOffPolicy Successful Response
     * @throws ApiError
     */
    public static getPoliciesForEmployeeApiV1TimeOffPoliciesEmployeeIdPoliciesGet(
        employeeId: string,
    ): CancelablePromise<Array<TimeOffPolicy>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/time_off_policies/{employee_id}/policies',
            path: {
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Policy Mappings For Employee
     * @param employeeId
     * @returns TimeOffPolicyMapping Successful Response
     * @throws ApiError
     */
    public static getPolicyMappingsForEmployeeApiV1TimeOffPoliciesEmployeeIdPolicyMappingsGet(
        employeeId: string,
    ): CancelablePromise<Array<TimeOffPolicyMapping>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/time_off_policies/{employee_id}/policy_mappings',
            path: {
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Generate Applicable Policies For Nascent Employee
     * @param requestBody
     * @returns TimeOffPolicy Successful Response
     * @throws ApiError
     */
    public static generateApplicablePoliciesForNascentEmployeeApiV1TimeOffPoliciesNascentApplicablePoliciesPost(
        requestBody: NascentEmployeeForTimeOff,
    ): CancelablePromise<Array<TimeOffPolicy>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/time_off_policies/nascent_applicable_policies',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Assign Policy For Employee
     * @param policyId
     * @param employeeId
     * @param balanceHours
     * @returns string Successful Response
     * @throws ApiError
     */
    public static assignPolicyForEmployeeApiV1TimeOffPoliciesPolicyPolicyIdEmployeesEmployeeIdAssignPut(
        policyId: string,
        employeeId: string,
        balanceHours?: (number | null),
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/time_off_policies/policy/{policy_id}/employees/{employee_id}/assign',
            path: {
                'policy_id': policyId,
                'employee_id': employeeId,
            },
            query: {
                'balance_hours': balanceHours,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Policy For Employee
     * @param policyMappingId
     * @returns string Successful Response
     * @throws ApiError
     */
    public static removePolicyForEmployeeApiV1TimeOffPoliciesRemoveEmployeePolicyMappingIdDelete(
        policyMappingId: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/time_off_policies/remove_employee/{policy_mapping_id}',
            path: {
                'policy_mapping_id': policyMappingId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Bulk Policy Update File
     * Returns a file of all active employees in te format needs for the bulk time off update
     * @param policyId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getBulkPolicyUpdateFileApiV1TimeOffPoliciesBulkUpdateEmployeesGet(
        policyId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/time_off_policies/bulk_update/employees',
            query: {
                'policy_id': policyId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Bulk Policy Update
     * Takes in a csv file with all updated mappings for a time off policy and updates or create the mappings for the policy.
     * @param policyId
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public static bulkPolicyUpdateApiV1TimeOffPoliciesBulkUpdatePolicyIdPost(
        policyId: string,
        formData: Body_bulk_policy_update_api_v1_time_off_policies_bulk_update__policy_id__post,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/time_off_policies/bulk_update/{policy_id}',
            path: {
                'policy_id': policyId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Decomposed Time Off Policy For Employee
     * Returns the decomposed time off policy for a given employee
     * @param policyId
     * @param employeeId
     * @returns DecomposedBalancePerPeriod Successful Response
     * @throws ApiError
     */
    public static getDecomposedTimeOffPolicyForEmployeeApiV1TimeOffPoliciesDecomposedPolicyIdEmployeeEmployeeIdGet(
        policyId: string,
        employeeId: string,
    ): CancelablePromise<DecomposedBalancePerPeriod> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/time_off_policies/decomposed/{policy_id}/employee/{employee_id}',
            path: {
                'policy_id': policyId,
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Adjustments For Employee And Policy
     * @param policyId
     * @param employeeId
     * @returns TimeOffAdjustment Successful Response
     * @throws ApiError
     */
    public static getAdjustmentsForEmployeeAndPolicyApiV1TimeOffPoliciesBalanceAdjustmentsPolicyIdEmployeeEmployeeIdGet(
        policyId: string,
        employeeId: string,
    ): CancelablePromise<Array<TimeOffAdjustment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/time_off_policies/balance_adjustments/{policy_id}/employee/{employee_id}',
            path: {
                'policy_id': policyId,
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Decomposed Hourly Accrual For Employee And Policy
     * @param policyId
     * @param employeeId
     * @returns DecomposedHourlyAccrual Successful Response
     * @throws ApiError
     */
    public static getDecomposedHourlyAccrualForEmployeeAndPolicyApiV1DecomposedHourlyAccrualTimeOffPolicyIdEmployeeEmployeeIdGet(
        policyId: string,
        employeeId: string,
    ): CancelablePromise<DecomposedHourlyAccrual> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/decomposed-hourly-accrual/time_off/{policy_id}/employee/{employee_id}',
            path: {
                'policy_id': policyId,
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Decomposed Daily Accrual For Employee And Policy
     * @param policyId
     * @param employeeId
     * @returns DecomposedDailyAccrual Successful Response
     * @throws ApiError
     */
    public static getDecomposedDailyAccrualForEmployeeAndPolicyApiV1DecomposedDailyAccrualTimeOffPolicyIdEmployeeEmployeeIdGet(
        policyId: string,
        employeeId: string,
    ): CancelablePromise<DecomposedDailyAccrual> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/decomposed-daily-accrual/time_off/{policy_id}/employee/{employee_id}',
            path: {
                'policy_id': policyId,
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Decomposed Granted Per Period For Employee And Policy
     * @param policyId
     * @param employeeId
     * @returns DecomposedGrantedBalance Successful Response
     * @throws ApiError
     */
    public static getDecomposedGrantedPerPeriodForEmployeeAndPolicyApiV1DecomposedGrantedBalanceTimeOffPolicyIdEmployeeEmployeeIdGet(
        policyId: string,
        employeeId: string,
    ): CancelablePromise<DecomposedGrantedBalance> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/decomposed-granted-balance/time_off/{policy_id}/employee/{employee_id}',
            path: {
                'policy_id': policyId,
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Assign Policy
     * @param requestBody
     * @returns TimeOffPolicyAssignmentResponse Successful Response
     * @throws ApiError
     */
    public static assignPolicyApiV2TimeOffPoliciesPolicyPost(
        requestBody: TimeOffPolicyAssignmentRequest,
    ): CancelablePromise<TimeOffPolicyAssignmentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v2/time_off_policies/policy',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
