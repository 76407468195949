/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ReceivablePaymentCreate = {
    accounts_receivable_invoice_id?: (string | null);
    amount_cents: number;
    batch_number?: (string | null);
    memo?: string;
    method?: ReceivablePaymentCreate.method;
    occurred_at?: (string | null);
    paid_reference_number?: (string | null);
    posted_at?: (string | null);
    status?: string;
    customer_id?: (string | null);
    source_type?: ReceivablePaymentCreate.source_type;
    payment_transaction_id?: (string | null);
    is_archived?: boolean;
    is_current?: boolean;
    target_version_id?: (string | null);
};

export namespace ReceivablePaymentCreate {

    export enum method {
        CREDIT_CARD = 'Credit Card',
        ACH = 'ACH',
    }

    export enum source_type {
        AUTOMATIC = 'Automatic',
        MANUAL = 'Manual',
    }


}

