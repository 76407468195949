/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UserNotificationDeliveryType {
    EMAIL = 'EMAIL',
    MOBILE_PUSH = 'MOBILE_PUSH',
    WEB_PUSH = 'WEB_PUSH',
    IN_APP = 'IN_APP',
}
