/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ShiftInstanceForBelltower } from '../models/ShiftInstanceForBelltower';
import type { ShiftInstanceForHighLevelBelltower } from '../models/ShiftInstanceForHighLevelBelltower';
import type { ShiftInstanceForLiveDrilldown } from '../models/ShiftInstanceForLiveDrilldown';
import type { SimpleShiftInstanceForAdhocReport } from '../models/SimpleShiftInstanceForAdhocReport';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BelltowerService {

    /**
     * Get Live Belltower
     * @returns ShiftInstanceForBelltower Successful Response
     * @throws ApiError
     */
    public static getLiveBelltowerApiV1BelltowerLiveGet(): CancelablePromise<Array<ShiftInstanceForBelltower>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/belltower/live',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Live Shift Drilldown
     * @param shiftInstanceId
     * @returns ShiftInstanceForLiveDrilldown Successful Response
     * @throws ApiError
     */
    public static getLiveShiftDrilldownApiV1BelltowerLiveShiftInstanceIdGet(
        shiftInstanceId: string,
    ): CancelablePromise<ShiftInstanceForLiveDrilldown> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/belltower/live/{shift_instance_id}',
            path: {
                'shift_instance_id': shiftInstanceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Simple Shift Instances For Adhoc Report
     * @param customerId
     * @returns SimpleShiftInstanceForAdhocReport Successful Response
     * @throws ApiError
     */
    public static getSimpleShiftInstancesForAdhocReportApiV1BelltowerCustomerCustomerIdGet(
        customerId: string,
    ): CancelablePromise<Array<SimpleShiftInstanceForAdhocReport>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/belltower/customer/{customer_id}',
            path: {
                'customer_id': customerId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Belltower Instances
     * @returns ShiftInstanceForHighLevelBelltower Successful Response
     * @throws ApiError
     */
    public static getBelltowerInstancesApiV1BelltowerHighLevelLiveGet(): CancelablePromise<Array<ShiftInstanceForHighLevelBelltower>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/belltower/high-level/live',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
