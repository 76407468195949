import AccountsIcon from '@mui/icons-material/HomeWorkOutlined'
import SchedulingIcon from '@mui/icons-material/CalendarMonthOutlined'
import PeopleIcon from '@mui/icons-material/Group'
import TimekeepingIcon from '@mui/icons-material/Schedule'
import BackpackIcon from '@mui/icons-material/Backpack'

import BillingIcon from '@mui/icons-material/RequestQuoteOutlined'
import AnalyticsIcon from '@mui/icons-material/AssessmentOutlined'
import BellTowerIcon from '@mui/icons-material/CellTowerOutlined'
import PayrollIcon from '@mui/icons-material/AccountBalanceOutlined'
import SettingsIcon from '@mui/icons-material/Settings'
import AddBusinessIcon from '@mui/icons-material/AddBusiness'
import MoneyIcon from '@mui/icons-material/Money'
import { FirmAddon, type FirmWithAddons } from 'src/generated'
import { doesFirmHaveAddon } from 'src/services/firm'
import {
    ADMIN_ROLE,
    FINANCE_ROLE,
    HR_ROLE,
    MANAGER_ROLE,
    type Role,
} from 'src/utils/auth'
import { FeatureGate, Features } from 'src/constants/previewEligibleOrgs'
import { ChatNavIcon } from '../ChatNavIcon/ChatNavIcon'

const KNOWN_EXTERNAL_URL_SIGILS = ['mineral']
type NavItemVisibilityDeterminants = {
    rolesOfUser: Role[]
    firm: FirmWithAddons
}

export type NavMenuLink = {
    title: string

    // If defined, will be used as the page title in the app bar. Otherwise the app bar title will be the same as title of the link
    appBarTitle?: string | undefined

    icon?: JSX.Element | undefined
    children?: NavMenuLink[] | undefined

    // The path this link "represents." This link will be marked as the active route only if the current route matches this path
    path: string

    // The next.js query param name, if defined. Used when comparing to the router path since nextjs includes these params in the form /path/[[...paramName]]
    paramName?: string | undefined

    // Any other paths that should be considered to "match" this option. (Currently: Needed to support the timekeeping routes sub-sub-paths)
    additionalPaths?: string[] | undefined

    // Where this link should take you when you click. Distinct from path since many top level options link directly to a child
    href?: string | undefined

    // If set to true, href's value will be ignored and the path of the options first child will be used as the href instead (Can't just hardcode that path. It might not actually be accessible to the logged in user)
    linkToFirstChild?: boolean | undefined

    // Access rules
    requireOneOfRole?: Role[]
    addonRequired?: FirmAddon.addon_kind | undefined

    previewFeatureRequired?: Features
}

const NAV_OPTIONS_MANIFEST: NavMenuLink[] = [
    {
        title: 'Bell Tower',
        icon: <BellTowerIcon />,
        path: '/belltower',
        linkToFirstChild: true,
        children: [
            {
                title: 'Live Dashboard',
                path: '/belltower/livedashboard',
            },
            {
                title: 'Activities',
                path: '/belltower/activities',
            },
            {
                title: 'Reports',
                path: '/belltower/reports',
            },
            {
                title: 'Report Templates',
                path: '/belltower/report-templates',
            },
            {
                title: 'Announcements',
                path: '/belltower/announcements',
            },
            {
                title: 'Dispatch',
                path: '/belltower/dispatch',
                previewFeatureRequired: Features.DISPATCH,
            },
        ],
    },
    {
        title: 'Chat',
        icon: <ChatNavIcon />,
        path: '/chat',
    },
    {
        title: 'Scheduling',
        icon: <SchedulingIcon />,
        path: '/scheduling',
        linkToFirstChild: true,
        children: [
            {
                title: 'Schedule',
                path: '/scheduling/details',
            },
            {
                title: 'Offers',
                path: '/scheduling/marketplace',
            },
            {
                title: 'Hours by Officer',
                path: '/scheduling/officer',
            },
            {
                title: 'Hours by Site',
                path: '/scheduling/customer',
            },
            {
                title: 'Time Off',
                path: '/scheduling/time_off/upcoming',
                additionalPaths: [
                    '/scheduling/time_off/pending',
                    '/scheduling/time_off/past',
                ],
            },
        ],
    },
    {
        title: 'Customers',
        icon: <AccountsIcon />,
        path: '/customers',
        linkToFirstChild: true,
        children: [
            {
                title: 'Sites',
                href: '/customers/sites',
                path: '/customers/sites',
            },
            {
                title: 'Parents',
                href: '/customers/parents',
                path: '/customers/parents',
            },
        ],
    },
    {
        title: 'People',
        icon: <PeopleIcon />,
        path: '/employees',
        href: '',
        linkToFirstChild: true,
        children: [
            {
                title: 'All People',
                path: '/employees',
            },
            {
                title: 'Onboarding',
                href: '/employees/onboarding',
                path: '/employees/onboarding',
                requireOneOfRole: [
                    ADMIN_ROLE,
                    MANAGER_ROLE,
                    HR_ROLE,
                    FINANCE_ROLE,
                ],
                addonRequired: FirmAddon.addon_kind.EMPLOYEE_ONBOARDING,
            },
        ],
    },
    {
        title: 'Timekeeping',
        icon: <TimekeepingIcon />,
        path: '/timekeeping',
        linkToFirstChild: true,
        children: [
            {
                title: 'Daily',
                path: '/timekeeping/day',
            },
            {
                title: 'Pay Period',
                path: '/timekeeping/period',
                href: '/timekeeping/period/default',
                requireOneOfRole: [
                    ADMIN_ROLE,
                    MANAGER_ROLE,
                    HR_ROLE,
                    FINANCE_ROLE,
                ],
                paramName: 'id',
            },
        ],
    },
    {
        title: 'Payroll & HR',
        appBarTitle: 'Payroll',
        icon: <PayrollIcon />,
        href: '',
        path: '/payroll',
        paramName: 'payrollParams',
        linkToFirstChild: true,
        requireOneOfRole: [ADMIN_ROLE, HR_ROLE, FINANCE_ROLE],
        addonRequired: FirmAddon.addon_kind.CHECKHQ_PAYROLL,
        children: [
            {
                title: 'Payroll',
                path: '/payroll',
                paramName: 'payrollParams',
                requireOneOfRole: [ADMIN_ROLE, HR_ROLE, FINANCE_ROLE],
                addonRequired: FirmAddon.addon_kind.CHECKHQ_PAYROLL,
            },
            {
                title: 'Payroll V1',
                path: '/payroll/v1',
                paramName: 'payrollParams',
                requireOneOfRole: [ADMIN_ROLE, HR_ROLE],
                addonRequired: FirmAddon.addon_kind.CHECKHQ_PAYROLL,
                previewFeatureRequired: Features.PAYROLL_V2_SIDE_BY_SIDE,
            },
            {
                title: 'Payroll V2',
                path: '/payroll/v2',
                paramName: 'payrollParams',
                requireOneOfRole: [ADMIN_ROLE, HR_ROLE],
                addonRequired: FirmAddon.addon_kind.CHECKHQ_PAYROLL,
                previewFeatureRequired: Features.PAYROLL_V2_SIDE_BY_SIDE,
            },
            {
                title: 'Benefits Admin',
                path: '/payroll/benefits_admin',
                href: '/payroll/benefits_admin',
                requireOneOfRole: [ADMIN_ROLE, HR_ROLE, FINANCE_ROLE],
                addonRequired: FirmAddon.addon_kind.BENEFITS_ADMIN,
            },
            {
                title: 'Mineral',
                path: '',
                href: 'mineral',
                requireOneOfRole: [ADMIN_ROLE, HR_ROLE, FINANCE_ROLE],
                addonRequired: FirmAddon.addon_kind.CHECKHQ_PAYROLL,
            },
        ],
    },
    {
        title: 'Billing',
        icon: <BillingIcon />,
        href: '/billing',
        path: '/billing',
        paramName: 'invoiceParams',
        requireOneOfRole: [ADMIN_ROLE, FINANCE_ROLE],
        children: [
            {
                title: 'Transactions',
                path: '/billing/transactions',
                href: '/billing/transactions',
                requireOneOfRole: [ADMIN_ROLE, FINANCE_ROLE],
                addonRequired: FirmAddon.addon_kind.PAYMENT_ACCEPTANCE,
            },
            {
                title: 'Deposits',
                path: '/billing/deposits',
                href: '/billing/deposits',
                requireOneOfRole: [ADMIN_ROLE, FINANCE_ROLE],
                addonRequired: FirmAddon.addon_kind.PAYMENT_ACCEPTANCE,
            },
            {
                title: 'Manual Payments',
                path: '/accountsreceivable/payments',
                requireOneOfRole: [ADMIN_ROLE, FINANCE_ROLE],
                previewFeatureRequired: Features.ACCOUNTS_RECEIVABLE,
            },
        ],
    },
    {
        title: 'Accounts Payable',
        icon: <MoneyIcon />,
        href: '/accountspayable',
        path: '/accountspayable',
        requireOneOfRole: [ADMIN_ROLE, FINANCE_ROLE],
        linkToFirstChild: true,
        previewFeatureRequired: Features.ACCOUNTS_PAYABLE,
        children: [
            {
                title: 'Invoices',
                path: '/accountspayable/invoices',
                requireOneOfRole: [ADMIN_ROLE],
            },
            {
                title: 'Payments',
                path: '/accountspayable/payments',
                requireOneOfRole: [ADMIN_ROLE],
            },
            {
                title: 'Vendors',
                path: '/accountspayable/vendors',
                requireOneOfRole: [ADMIN_ROLE],
            },
        ],
    },
    {
        title: 'Inventory',
        icon: <BackpackIcon />,
        href: '/inventory',
        path: '/inventory',
        requireOneOfRole: [ADMIN_ROLE, FINANCE_ROLE, HR_ROLE, MANAGER_ROLE],
        linkToFirstChild: true,
        previewFeatureRequired: Features.INVENTORY,
        children: [
            {
                title: 'Units',
                path: '/inventory/items',
            },
            {
                title: 'Item Templates',
                path: '/inventory/itemtemplates',
            },
        ],
    },
    {
        title: 'Connect',
        icon: <AddBusinessIcon />,
        href: '/connect',
        path: '/connect',
        requireOneOfRole: [ADMIN_ROLE],
    },
    {
        title: 'Analytics',
        icon: <AnalyticsIcon />,
        path: '/analytics',
        requireOneOfRole: [ADMIN_ROLE, MANAGER_ROLE, HR_ROLE, FINANCE_ROLE],
        linkToFirstChild: true,
        children: [
            {
                title: 'Financial Reports',
                path: '/analytics/financial/hours',
                requireOneOfRole: [ADMIN_ROLE],
            },
            {
                title: 'Operations',
                path: '/analytics/operations/incidents',
                requireOneOfRole: [
                    ADMIN_ROLE,
                    MANAGER_ROLE,
                    HR_ROLE,
                    FINANCE_ROLE,
                ],
            },
            {
                title: 'Licenses and Certs',
                path: '/analytics/licenses_certs',
                requireOneOfRole: [
                    ADMIN_ROLE,
                    MANAGER_ROLE,
                    HR_ROLE,
                    FINANCE_ROLE,
                ],
            },
            {
                title: 'Changelog',
                path: '/analytics/changelog/payroll',
                requireOneOfRole: [
                    ADMIN_ROLE,
                    MANAGER_ROLE,
                    HR_ROLE,
                    FINANCE_ROLE,
                ],
            },
            {
                title: 'Site Health',
                path: '/analytics/site_health/turnover_risk',
                requireOneOfRole: [
                    ADMIN_ROLE,
                    MANAGER_ROLE,
                    HR_ROLE,
                    FINANCE_ROLE,
                ],
            },
        ],
    },
    {
        title: 'Settings',
        icon: <SettingsIcon />,
        href: '/settings',
        path: '/settings',
    },
]

function filterByRules(
    navOptions: NavMenuLink[],
    determinants: NavItemVisibilityDeterminants,
    externalUrls: Record<string, string | undefined>,
    featureGate: FeatureGate
) {
    return navOptions.filter((navOption) => {
        const hasRequiredRoles = navOption.requireOneOfRole
            ? navOption.requireOneOfRole.some((requiredRole) =>
                  determinants.rolesOfUser.includes(requiredRole)
              )
            : true
        const hasRequiredAddons = navOption.addonRequired
            ? doesFirmHaveAddon(determinants.firm, navOption.addonRequired)
            : true

        const hasRequiredPreviewFeature =
            navOption.previewFeatureRequired && determinants.firm.id
                ? featureGate[navOption.previewFeatureRequired]?.includes(
                      determinants.firm.id
                  )
                : true

        const externalUrlRequired =
            navOption.href && KNOWN_EXTERNAL_URL_SIGILS.includes(navOption.href)

        return (
            hasRequiredAddons &&
            hasRequiredRoles &&
            hasRequiredPreviewFeature &&
            !(externalUrlRequired && !externalUrls[navOption.href!])
        )
    })
}

function setExternalPaths(
    navOption: NavMenuLink,
    externalUrls: Record<string, string | undefined>
) {
    if (navOption.href && Object.keys(externalUrls).includes(navOption.href)) {
        return {
            ...navOption,
            href: externalUrls[navOption.href],
        }
    }
    return navOption
}

export const getNavOptions = (
    rolesOfUser: Role[],
    firm: FirmWithAddons,
    externalUrls: Record<string, string | undefined>,
    featureGate: FeatureGate
): NavMenuLink[] => {
    const determinants = { rolesOfUser, firm }
    return filterByRules(
        NAV_OPTIONS_MANIFEST,
        determinants,
        externalUrls,
        featureGate
    )
        .map((navOption) => {
            if (!navOption.children) {
                return navOption
            }

            return {
                ...navOption,
                children: filterByRules(
                    navOption.children,
                    determinants,
                    externalUrls,
                    featureGate
                ),
            }
        })
        .map((navOption) => {
            if (!navOption.linkToFirstChild) return navOption
            const firstChildOption = navOption.children && navOption.children[0]
            if (!firstChildOption) return navOption

            // Now that the child options have been filtered, we can actually figure out where the parent is allowed to link to:
            return {
                ...navOption,
                href: firstChildOption.path,
            }
        })
        .map((navOption) => {
            navOption = setExternalPaths(navOption, externalUrls)
            return {
                ...navOption,
                children: navOption.children?.map((navOption) =>
                    setExternalPaths(navOption, externalUrls)
                ),
            }
        })
}
