/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ChangeSource {
    EARNING = 'earning',
    USAGE = 'usage',
    RESET = 'reset',
    UNCATEGORIZED_ADJUSTMENT = 'uncategorized_adjustment',
    EARNING_ADJUSTMENT = 'earning_adjustment',
    STARTING_BALANCE = 'starting_balance',
    EARNING_RECONCILIATION = 'earning_reconciliation',
    BALANCE_RECONCILIATION = 'balance_reconciliation',
}
